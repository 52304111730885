import React, { useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { LogoDark } from "../../Constants/Images";

import { setMenuState } from "../../Store/Actions/CommanAction";
import { userImage } from "../../Constants/Images";
// import ProfileModal from "../Modals/ProfileModal";
// import { BASE_URL } from "../../Utils/env";

const Header = ({ menuState, setMenuState, user, isLogin }) => {
    const [showProfileModal, setProfileModal] = useState(false);
    
    const profileImg = Object.entries(user).length > 0 && user.profile_img ? user.profile_img.url : userImage;

    return (
        <div className="admin-header">
            <span
                className="hamberger"
                onClick={() => {
                    setMenuState(!menuState);
                }}
            >
                <FontAwesomeIcon icon={faBars} />
            </span>
            <div className="container">
                <div className="app-logo-wrapper">
                    <div className="logo">
                        <Link to="/causes">
                            <Image src={LogoDark} />
                        </Link>
                    </div>
                </div>
                {isLogin && (
                    <div className="user-dropdown-wrapper">
                        <Dropdown onClick={() => setProfileModal(true)}>
                            <Dropdown.Toggle variant="secondary" className="user-dropdown">
                                <Image src={profileImg} /> {user.display_name ? user.display_name : user.name}
                            </Dropdown.Toggle>
                        </Dropdown>
                        {/* {showProfileModal && (
                            <ProfileModal show={showProfileModal} closeModal={() => setProfileModal(false)} />
                        )} */}
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateProps = (state) => ({
    menuState: state.menuState,
    user: state.user,
    isLogin: !!state.user.authToken,
});

export default connect(mapStateProps, { setMenuState })(Header);
