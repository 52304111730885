import { combineReducers } from "redux";
import { user } from "./AuthReducer";

import { menuState, settings, notifications, pageLoaded } from "./CommanReducers";
// import { causeCategory, adminCauses } from "./CausesReduces-Admin";
import { organizations,termPages } from "./AdminReducer";

import { users } from "./UserReducer";

export default combineReducers({
    pageLoaded,
    menuState,
    organizations,
    settings,
    notifications,
    user,
    users,    
    termPages,
});
