import React from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';

import Dashboard from './Dashboard';
import OrgList from './OrgList';
//import CauseCategories from "./CauseCategories";

import Users from './Users';

// import PagesContent from "./PagesContent";
import Notifications from './Notifications';
import Settings from './Settings';

// import PageNotFound from "../404";

import {Header, SidebarMenu} from '../../Components';

const AdminPage = ({location, menuState}) => {
  const isOpen = menuState ? 'open' : '';
  return (
    <div className={`admin-wrapper ${isOpen}`}>
      <SidebarMenu location={location} />
      <div className="admin-content">
        <Header />

        <Route exact path="/admin" component={Dashboard} />
        <Route exact path="/admin/dashboard" component={Dashboard} />
        <Route exact path="/admin/organizations" component={OrgList} />
        <Route
          exact
          path="/admin/organizations/page/:page"
          component={OrgList}
        />
        {/* <Route exact path="/admin/categories" component={CauseCategories} />                
                <Route exact path="/admin/categories/page/:page" component={CauseCategories} /> */}

        <Route exact path="/admin/users" component={Users} />
        <Route exact path="/admin/users/page/:page" component={Users} />
        {/* <Route exact path="/admin/pages-content" component={PagesContent} />
                <Route exact path="/admin/pages-content/:params" component={PagesContent} /> */}

        <Route exact path="/admin/notificatoins" component={Notifications} />
        <Route
          exact
          path="/admin/notificatoins/:params"
          component={Notifications}
        />
        <Route exact path="/admin/settings" component={Settings} />
        <Route exact path="/admin/settings/:param" component={Settings} />
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
});

export default connect(mapStateProps, {})(AdminPage);
