import API from '../../Utils/API';

import {setItem, getItem} from '../../Utils/LocalStorage';
import {loginDispatch} from './AuthActions';
import {
  PAGE_LOADED,
  SIDEBAR_MENU_STATE,
  GET_SETTINGS,
  GET_NOTIFICATIONS,
} from '../../Constants/Types';

/**
 *
 */
export const updatePageLoadedDispatch = status => ({
  type: PAGE_LOADED,
  status,
});

export const setMenuStateDispatch = status => ({
  type: SIDEBAR_MENU_STATE,
  status,
});

export const setMenuState = status => dispatch => {
  setItem('showSidebar', status);
  dispatch(setMenuStateDispatch(status));
};

/**
 * Settings
 **/

export const setSaveSettingsDispatch = settings => ({
  type: GET_SETTINGS,
  settings,
});

export const saveSettings = data => dispatch =>
  API.saveSettings(data).then(res => {
    const {settings} = res;
    if (res.type === 'success') {
      dispatch(setSaveSettingsDispatch(settings));
    }
    return res;
  });

export const getSettings = data => dispatch =>
  API.getSettings(data).then(res => {
    const {settings} = res;
    if (res.type === 'success') {
      dispatch(setSaveSettingsDispatch(settings));
    }
    return res;
  });

/**
 * Save & Retrieve Notification Mails
 */
export const getNotificationsDispatch = notifications => ({
  type: GET_NOTIFICATIONS,
  notifications,
});

export const saveNotifications = data => dispatch =>
  API.saveNotifications(data).then(res => {
    const {type, notifications} = res;

    if (notifications && type === 'success') {
      dispatch(getNotificationsDispatch(notifications));
    }

    return res;
  });

export const getNotifications = data => dispatch =>
  API.getNotifications(data).then(res => {
    const {type, notifications} = res;

    if (notifications && type === 'success') {
      dispatch(getNotificationsDispatch(notifications));
    }

    return res;
  });
