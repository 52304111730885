import React, {useState, useEffect} from 'react';
import {Alert, Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import PhoneInput from 'react-phone-input-2';

import {updateOrganization} from '../../../Store/Actions/AdminAction';
import {ALLOWED_COUNTRY} from '../../../Utils/env';
import {validateEmail} from '../../../Utils/Helpers';
import {usStates, categories} from '../../../Constants';

const UpdateOrg = ({show, orgData, closeModal, updateOrganization}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [alertData, setAlertData] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const onChange = (field, value) => {
    const tempData = {
      ...data,
      [field]: value,
    };
    setData(tempData);
  };
  const onLocationChange = (field, value) => {
    const tempData = {
      ...data,
      location: {
        ...data.location,
        [field]: value,
      },
    };
    setData(tempData);
  };

  const validateData = () => {
    const errs = {};

    if (!data.name || data.name === '') {
      errs.name = 'Please enter Organization name.';
    } else if (!data.category || data.category === '') {
      errs.category = 'Please choose Category.';
    } else if (!data.email || data.email === '') {
      errs.email = 'Please enter Email address.';
    } else if (data.email && !validateEmail(data.email)) {
      errs.email = 'Please enter valid Email address.';
    } else if (!data.address || data.address === '') {
      errs.address = 'Please enter Address.';
    } else if (!data.state || data.state === '') {
      errs.state = 'Please Choose State.';
    }

    return errs;
  };

  const onSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    const tempErrs = validateData();
    setErrors(tempErrs);

    if (!loading && Object.entries(tempErrs).length === 0) {
      setLoading(true);

      const tempData = {...data};
      tempData.phone = `+${data.phone}`;

      console.log('New Org :', tempData);

      updateOrganization({data: tempData})
        .then(res => {
          setLoading(false);

          if (res.type === 'success') {
            setData(res.item);
          }
          setAlertData({
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          });
        })
        .catch(err => {
          setLoading(false);
          setAlertData({
            show: true,
            type: 'danger',
            message: err.message,
          });
        });
    }
  };

  const hideErrMsg = () => {
    setAlertData({
      show: false,
    });
  };

  useEffect(() => {
    setData(orgData);
  }, [orgData]);

  console.log('Data :', data);

  return (
    <Modal className="medium" show={show} onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title>Update Organization</Modal.Title>
        <span
          className="close as-link"
          onClick={() => {
            closeModal();
          }}>
          x
        </span>
      </Modal.Header>
      <Modal.Body>
        {alertData && alertData.show && (
          <Alert
            variant={alertData.type}
            onClose={() => hideErrMsg()}
            dismissible>
            {alertData.message}
          </Alert>
        )}

        <div className="modal-form-wrapper">
          <Form onSubmit={e => onSubmit(e)}>
            <Row>
              <Col md={6} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder=""
                    value={data.name}
                    onChange={e => onChange('name', e.target.value)}
                  />
                  {errors.name && (
                    <span className="errorMsg">{errors.name}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>category</Form.Label>
                  <div className="select-wrapper">
                    <select
                      name="category"
                      className="form-control"
                      value={data.category ? data.category : ''}
                      onChange={e => onChange('category', e.target.value)}>
                      <option value="">Select</option>
                      {categories &&
                        categories.map((item, index) => (
                          <option key={`category-${index}`} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors.category && (
                    <span className="errorMsg">{errors.category}</span>
                  )}
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Description</Form.Label>
                  <textarea
                    name="description"
                    value={data.description ? data.description : ''}
                    rows={3}
                    className="form-control"
                    onChange={e => onChange('description', e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={6} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="text"
                    placeholder=""
                    value={data.email ? data.email : ''}
                    onChange={e => onChange('email', e.target.value)}
                  />
                  {errors.email && (
                    <span className="errorMsg">{errors.email}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>phone</Form.Label>
                  <PhoneInput
                    className="form-control"
                    onlyCountries={ALLOWED_COUNTRY}
                    country={'us'}
                    value={data.phone ? data.phone : ''}
                    onChange={phone => onChange('phone', phone)}
                  />
                  {errors.phone && (
                    <span className="errorMsg">{errors.phone}</span>
                  )}
                </Form.Group>
              </Col>

              <Col md={6} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    name="address"
                    type="text"
                    placeholder=""
                    value={data.address ? data.address : ''}
                    onChange={e => onChange('address', e.target.value)}
                  />
                  {errors.address && (
                    <span className="errorMsg">{errors.address}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>State</Form.Label>
                  <div className="select-wrapper">
                    <select
                      name="state"
                      className="form-control"
                      value={data.state ? data.state : ''}
                      onChange={e => onChange('state', e.target.value)}>
                      <option value="">Select</option>
                      {usStates &&
                        usStates.map((item, index) => (
                          <option key={`state-${index}`} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors.state && (
                    <span className="errorMsg">{errors.state}</span>
                  )}
                </Form.Group>
              </Col>

              <Col md={6} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    name="latitude"
                    type="text"
                    placeholder=""
                    disabled
                    value={
                      data.location && data.location.coordinates[1]
                        ? data.location.coordinates[1]
                        : ''
                    }
                    onChange={e => onLocationChange('latitude', e.target.value)}
                  />
                  {errors.latitude && (
                    <span className="errorMsg">{errors.latitude}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    name="longitude"
                    type="text"
                    placeholder=""
                    disabled
                    value={
                      data.location && data.location.coordinates[0]
                        ? data.location.coordinates[0]
                        : ''
                    }
                    onChange={e =>
                      onLocationChange('longitude', e.target.value)
                    }
                  />
                  {errors.longitude && (
                    <span className="errorMsg">{errors.longitude}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    name="website"
                    type="url"
                    placeholder=""
                    value={data.website ? data.website : ''}
                    onChange={e => onChange('website', e.target.value)}
                  />
                  {errors.website && (
                    <span className="errorMsg">{errors.website}</span>
                  )}
                </Form.Group>
              </Col>

              <Col md={12} xs={12}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Intakes</Form.Label>
                  <Form.Control
                    name="intakes"
                    type="text"
                    placeholder=""
                    value={data.intakes ? data.intakes : ''}
                    onChange={e => onChange('intakes', e.target.value)}
                  />
                  {errors.intakes && (
                    <span className="errorMsg">{errors.intakes}</span>
                  )}
                </Form.Group>
              </Col>

              <Col md={12} xs={12}>
                <Form.Group className="form-group mb-0" controlId="name">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    name="tags"
                    type="text"
                    placeholder=""
                    value={data.tags ? data.tags : ''}
                    onChange={e => onChange('tags', e.target.value)}
                  />
                  {errors.tags && (
                    <span className="errorMsg">{errors.tags}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button onClick={() => onSubmit()}>
          {!loading && 'Save'}
          {loading && <i className="fas fa-spinner fa-spin" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(null, {updateOrganization})(UpdateOrg);
