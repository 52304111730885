import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
    const { title, parent, className, history } = props;

    return (
        <div className={`breadcrumb ${className}`}>
            {history && (
                <span className="back-btn btn btn-small btn-outline" onClick={() => history.goBack()}>
                    <i className="fa fa-chevron-left" />
                    Back
                </span>
            )}
            <ul>
                <li>
                    <span>
                        <Link to="/causes">
                            <i className="icon fas fa-home" />
                        </Link>
                    </span>
                </li>
                {parent && (
                    <li>
                        <span>
                            <Link to={parent.link}>{parent.title}</Link>
                        </span>
                    </li>
                )}
                <li>
                    <span>{title}</span>
                </li>
            </ul>
        </div>
    );
};

export default Breadcrumb;
