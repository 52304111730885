import React, { Component } from "react";
import { Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebookF, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

class SocialIcons extends Component {
    state = {
        socialNames: ["Instagram", "Facebook", "Twitter", "Youtube", "Website"],
        localIcons: [],
    };

    UNSAFE_componentDidMount = () => {
        this.setState({
            localIcons: this.props.icons,
        });
    };

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            localIcons: nextProps.icons,
        });
    };

    isIncluded = (social) => {
        const { localIcons } = this.state;
        const isThere = localIcons.filter((item) => {
            return `${item.social}` === `${social}`;
        });

        return isThere.length ? true : false;
    };

    toggleSocial = (social) => {
        const { localIcons } = this.state;
        if (!this.isIncluded(social)) {
            const tempData = [
                ...localIcons,
                {
                    social,
                    link: "",
                },
            ];

            this.props.updateIcons(tempData);
        } else {
            const newData = this.removeIcons(social);
            this.props.updateIcons(newData);
        }
    };

    removeIcons = (social) => {
        const { localIcons } = this.state;
        if (Object.entries(localIcons).length > 1) {
            const newData = localIcons.filter((item) => {
                return `${item.social}` !== `${social}`;
            });
            return newData;
        } else {
            return localIcons;
        }
    };

    updateSocialLink = (e, social) => {
        const { localIcons } = this.state;
        const link = e.target.value;
        for (let i = 0; i < localIcons.length; i++) {
            if (localIcons[i]["social"] === social) {
                localIcons[i]["link"] = link;
            }
        }
        this.props.updateIcons(localIcons);
    };

    getListItem = (social) => {
        const { localIcons } = this.state;
        const tempItem = localIcons.filter((item) => item.social === social);
        return Object.entries(tempItem).length > 0 ? tempItem[0] : {};
    };

    render() {
        const { socialNames } = this.state;

        const instaActive = this.isIncluded("Instagram") ? "active" : "";
        const facebookActive = this.isIncluded("Facebook") ? "active" : "";
        const twitterActive = this.isIncluded("Twitter") ? "active" : "";
        const youtubeActive = this.isIncluded("Youtube") ? "active" : "";
        const websiteActive = this.isIncluded("Website") ? "active" : "";

        return (
            <div className="social-wrapper">
                <div className="social-icons light">
                    <FontAwesomeIcon
                        icon={faInstagram}
                        className={`icons ${instaActive}`}
                        onClick={() => this.toggleSocial("Instagram")}
                    />
                    <FontAwesomeIcon
                        icon={faFacebookF}
                        className={`icons ${facebookActive}`}
                        onClick={() => this.toggleSocial("Facebook")}
                    />
                    <FontAwesomeIcon
                        icon={faTwitter}
                        className={`icons ${twitterActive}`}
                        onClick={() => this.toggleSocial("Twitter")}
                    />
                    <FontAwesomeIcon
                        icon={faYoutube}
                        className={`icons ${youtubeActive}`}
                        onClick={() => this.toggleSocial("Youtube")}
                    />
                    <FontAwesomeIcon
                        icon={faGlobe}
                        className={`icons ${websiteActive}`}
                        onClick={() => this.toggleSocial("Website")}
                    />
                </div>
                <div className="social-links">
                    {socialNames.length &&
                        socialNames.map((item, index) => {
                            // console.log("Item", item);

                            if (this.isIncluded(item)) {
                                const linkItem = this.getListItem(item);

                                return (
                                    <Form.Group key={`link_field_${index}`} className="form-group">
                                        <Form.Control
                                            type="text"
                                            value={linkItem.link}
                                            onChange={(e) => this.updateSocialLink(e, item)}
                                            placeholder={`${item} Link`}
                                        />
                                    </Form.Group>
                                );
                            } else {
                                return <span key={`link_field_${index}`} />;
                            }
                        })}
                </div>
            </div>
        );
    }
}

export default SocialIcons;
