import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert, Col, Row} from 'react-bootstrap';

import moment from 'moment';
import MomentDate from 'react-moment';

import {getDashboardData} from '../../Store/Actions/AdminAction';
import {LineChart} from '../../Components/Charts';

class Dashboard extends Component {
  state = {
    loading: false,
    recentUsers: [],
    monthlyUsers: [],
    alertData: {},
  };

  UNSAFE_componentWillMount = () => {
    this.getDashBoardData();
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {usStates, regions} = props;

    this.setState({usStates, regions});
  };

  getDashBoardData = () => {
    const {loading, currentRegion} = this.state;

    if (!loading) {
      this.props
        .getDashboardData(currentRegion)
        .then(res => {
          const {recentUsers, monthlyUsers} = res.data;

          this.setState({
            recentUsers,
            monthlyUsers,
            loading: false,
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
          console.log('err :', err);
        });
    }
  };

  renderUser = item => {
    const timestamp = moment.utc(parseInt(item.add_time) * 1000);
    return (
      <tr key={`user_key_${item.id}`}>
        <td>{item.name}</td>
        <td>{item.phone}</td>
        <td>{item.email}</td>
        <td>{String(item.auth_type).toUpperCase()}</td>

        <td className="text-center" valign="middle">
          <MomentDate date={timestamp} format="DD/MM/YYYY" />
        </td>
      </tr>
    );
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  render() {
    const {alertData, recentUsers, monthlyUsers} = this.state;

    return (
      <div className="admin-body">
        <div className="container">
          <div className="body-card">
            <div className="heading d-flex align-items-center mt-4 mb-3">
              <h2>Dashboard</h2>
            </div>

            <div className="dashboard-content">
              {alertData && alertData.show && (
                <div className="alerts">
                  <Alert
                    variant={alertData.type}
                    onClose={() => this.hideErrMsg()}
                    dismissible>
                    {alertData.message}
                  </Alert>
                </div>
              )}

              {/**
               * Charts
               */}
              <Row className="mt-4">
                <Col md={6}>
                  <div className="card">
                    <div className="card-content">
                      <h3>Recent Users</h3>
                      <div className="cause-lists">
                        <div className="card">
                          <div className="card-content">
                            <div className="cause-lists listing-tables">
                              <table className="table table-inverse table-responsive mb-0">
                                <thead className="thead-inverse">
                                  <tr>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Auth Type</th>
                                    <th width="120" className="text-center">
                                      Date
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(recentUsers).length === 0 && (
                                    <tr>
                                      <td align="center" colSpan={5}>
                                        No Users Available.
                                      </td>
                                    </tr>
                                  )}
                                  {Object.entries(recentUsers).length > 0 &&
                                    recentUsers.map(item =>
                                      this.renderUser(item),
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="card">
                    <div className="card-content">
                      <div className="card-heading">
                        <h3>Monthly Users</h3>
                      </div>
                      <div className="cause-lists">
                        {monthlyUsers && (
                          <LineChart
                            labels={monthlyUsers.labels}
                            data={monthlyUsers.data}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateProps = state => ({
  menuState: state.menuState,
});

export default connect(mapStateProps, {
  getDashboardData,
})(Dashboard);
