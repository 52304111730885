import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {Logo} from '../Constants/Images';
import PageNotFound from '../Pages/PageNotFound';

import Login from '../Pages/Auth/Login';

import AdminRoutes from './AdminRoutes';
// import AuthRoute from "./AuthRoute";
import GuestRoute from './GuestRoutes';
import Root from '../Pages/Admin/Root';
import LandingPage from '../Pages/LandingPage';

import PrivacyPolicy from '../Pages/PrivacyPolicy';

import '../Utils/Scripts';
import '../Utils/userLoginCheck';

const RoutesFile = props => {
  return (
    <div className="page-wrapper">
      <div id="loader" className="loader">
        <div className="loader-logo">
          <img src={Logo} alt="" />
        </div>
      </div>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <GuestRoute exact path="/login" component={Login} />
          <GuestRoute exact path="/verify-otp" component={Login} />

          <AdminRoutes path="/admin" component={Root} />

          {/* <Route exact path="/terms-and-conditions" component={TermsPage} />*/}
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </div>
  );
};

export default connect(null, {})(RoutesFile);
