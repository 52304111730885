export const appName = 'Uplift-U';
export const environment = 'Dev'; // Local | Dev | Live

const URLs = {
  Local: 'http://localhost:8080',
  Dev: 'https://api.upliftuapp.com',
  Live: 'https://build-foundation.org/api',
};

const site_url = {
  Local: `http://localhost:3000`,
  Dev: `https://uplift-u.bleedingbulbtest.com/`,
  Live: 'https://build-foundation.org/',
};

export const SITE_URL = site_url[environment];
export const BASE_URL = URLs[environment];
export const API_URL = `${BASE_URL}`;

export const ALLOWED_COUNTRY = ['in', 'us'];
