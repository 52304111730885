import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

export const BarChart = (props) => {
    const { labels, data } = props;

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "top",
            },
            title: {
                display: false,
            },
        },
    };
    const causeData = {
        labels,
        datasets: [
            {
                label: "Causes ",
                data,
                backgroundColor: "#d4ab41",
            },
        ],
    };

    return <Bar options={options} data={causeData} />;
};

export const LineChart = (props) => {
    const { labels, data } = props;

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "top",
            },
            title: {
                display: false,
            },
        },
    };
    const payData = {
        labels,
        datasets: [
            {
                label: "Amount ",
                data: data,
                borderColor: "#d4ab41",
                backgroundColor: "#000",
            },
        ],
    };

    return <Line options={options} data={payData} />;
};
