/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';

import MomentDate from 'react-moment';

// AddCauseFront, EditCauseFront
import {Breadcrumb, AddOrgModal, EditOrgModal} from '../../Components';
import {
  getOrganizations,
  removeOrganization,
} from '../../Store/Actions/AdminAction';
import {updateUrl} from '../../Utils/Helpers';

const OrgList = props => {
  const page = props.match.params.page ? props.match.params.page : 1;
  const {organizations, getOrganizations, removeOrganization} = props;

  const [states, setState] = useState({
    bulk_action_id: [],
    listLoading: false,
    add_org_modal: false,
    alertData: {},
  });

  const [orgsList, setOrgsList] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const toggleModal = (modal, value) => {
    setState({
      ...states,
      [modal]: value,
    });
  };

  const hideErrMsg = () => {
    setState({
      ...states,
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  const fetchCauses = page => {
    setState({
      ...states,
      listLoading: true,
    });

    const data = {
      search: '',
      page,
    };

    getOrganizations({data})
      .then(res => {
        const newState = {
          ...states,
          listLoading: false,
        };
        if (res.type === 'success') {
          const CurrentPageNo =
            res.pagging && res.pagging.current
              ? parseInt(res.pagging.current)
              : 1;

          const newUrl =
            parseInt(CurrentPageNo) === 1
              ? `/admin/organizations`
              : `/admin/organizations/page/${page}`;
          updateUrl(newUrl, props.history);

          newState.pagging = res.pagging;
          newState.page = CurrentPageNo;
        }
        setState(newState);
      })
      .catch(err => {
        console.log(err);
        setState({
          ...states,
          listLoading: false,
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  const deleteCause = id => {
    console.log('Remove Item :', id);
    if (!deleteLoading) {
      setDeleteLoading(true);
      setDeleteId(id);
      removeOrganization(id)
        .then(res => {
          setDeleteLoading(false);
          setDeleteId('');
          setState({
            ...states,
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        })
        .catch(err => {
          setDeleteLoading(false);
          setDeleteId('');
          setState({
            ...states,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  const toggleAllBulk = e => {
    let {bulk_action_id} = states;

    if (e.target.checked) {
      orgsList.forEach(item => {
        if (bulk_action_id.indexOf(`${item.id}`) === -1) {
          bulk_action_id.push(`${item.id}`);
        }
      });
    } else {
      bulk_action_id = [];
    }

    setState({
      ...states,
      bulk_action_id,
    });
  };

  const toggleBulkItems = e => {
    let {bulk_action_id} = states;

    if (e.target.checked) {
      if (bulk_action_id.indexOf(`${e.target.value}`) === -1) {
        bulk_action_id.push(`${e.target.value}`);
      }
    } else {
      bulk_action_id = bulk_action_id.filter(
        (item, index) => `${item}` !== `${e.target.value}`,
      );
    }

    setState({
      ...states,
      bulk_action_id,
    });
  };

  const enableEditModal = item => {
    const orgData = {...item};
    setState({
      ...states,
      edit_org_modal: true,
      edit_org: orgData,
    });
  };

  const _renderItems = item => {
    return (
      <tr key={`items_key_${item._id}`}>
        <td align="center">
          <input
            type="checkbox"
            name="bulk_action[]"
            value={item.id}
            checked={bulk_action_id.indexOf(`${item.id}`) !== -1 ? true : false}
            onChange={e => toggleBulkItems(e)}
          />
        </td>
        <td>{item.name}</td>
        <td>{item.category ? item.category : ''}</td>
        <td>{item.state}</td>
        <td>
          <MomentDate date={item.createdAt} format="MM/DD/YYYY HH:mm" />
        </td>
        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span className="actionIcon" onClick={() => enableEditModal(item)}>
              <i className="fas fa-edit" />
            </span>
            <span
              className="actionIcon danger"
              onClick={() => deleteCause(item._id)}>
              {deleteLoading && deleteId === item._id && (
                <i className="fas fa-spin fa-spinner" />
              )}
              {deleteId !== item._id && <i className="fas fa-trash" />}
            </span>
          </div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    if (page) {
      fetchCauses(page);
    }
  }, [page]);

  useEffect(() => {
    if (Object.entries(organizations).length > 0) {
      console.log('Update organizations', organizations);

      setOrgsList(organizations);
    } else {
      setOrgsList([]);
    }
  }, [organizations]);

  const {
    add_org_modal,
    edit_org_modal,
    edit_org,
    bulk_action_id,
    pagging,
    alertData,
    listLoading,
  } = states;

  return (
    <div className="admin-body">
      <div className="container">
        <Breadcrumb title="Organizations" />

        <div className="body-card">
          <div className="heading">
            <h2>Organizations</h2>
            <div className="filter-options-wrapper">
              <span
                className="btn btn-primary btn-small add-new-btn"
                onClick={() => {
                  toggleModal('add_org_modal', true);
                }}>
                Add New
              </span>
            </div>
          </div>

          <div className="body-content">
            <div className="listing-tables mt-0">
              {alertData && alertData.show && (
                <div className="alerts">
                  <Alert
                    variant={alertData.type}
                    onClose={() => hideErrMsg()}
                    dismissible>
                    {alertData.message}
                  </Alert>
                </div>
              )}

              <table className="table table-inverse table-responsive">
                <thead className="thead-inverse">
                  <tr>
                    <th width="10" className="text-center">
                      <input
                        type="checkbox"
                        id="chkAll"
                        onChange={e => {
                          toggleAllBulk(e);
                        }}
                      />
                    </th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>State</th>
                    <th width="130">Add Time</th>
                    {/* <th width="150">Date</th> */}
                    <th width="130" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listLoading && (
                    <tr>
                      <td align="center" colSpan={7}>
                        <span className="fas fa-spinner fa-spin" />
                      </td>
                    </tr>
                  )}
                  {!listLoading && Object.entries(orgsList).length === 0 && (
                    <tr>
                      <td align="center" colSpan={7}>
                        No Causes Available.
                      </td>
                    </tr>
                  )}
                  {!listLoading &&
                    Object.entries(orgsList).length > 0 &&
                    orgsList.map(cause => _renderItems(cause))}
                </tbody>
              </table>

              {pagging && Object.entries(pagging).length > 0 && (
                <div className="pagging-wrapper">
                  {pagging.current !== '' &&
                    pagging.pages !== '' &&
                    pagging.totalItems !== '' && (
                      <div className="pagging-text">
                        <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                      </div>
                    )}

                  <div className="pagging-btns">
                    {pagging.previous && (
                      <span
                        className="btn btn-small"
                        onClick={() => fetchCauses(pagging.previous)}>
                        Previous
                      </span>
                    )}
                    {pagging.next && (
                      <span
                        className="btn btn-small next-btn"
                        onClick={() => fetchCauses(pagging.next)}>
                        Next
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {add_org_modal && (
        <AddOrgModal
          show={add_org_modal}
          closeModal={() => toggleModal('add_org_modal', false)}
        />
      )}

      {edit_org_modal && (
        <EditOrgModal
          show={edit_org_modal}
          orgData={edit_org}
          closeModal={() => toggleModal('edit_org_modal', false)}
        />
      )}
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
  organizations: state.organizations,
  usStates: state.usStates,
});

export default connect(mapStateProps, {getOrganizations, removeOrganization})(
  OrgList,
);
