import React from "react";
import { connect } from "react-redux";

import { Breadcrumb, Header, FrontSidebar } from "../Components";

const Home = ({ location, menuState }) => {
    const isOpen = menuState ? "open" : "";
    return (
        <div className={`admin-wrapper ${isOpen}`}>
            <FrontSidebar location={location} />
            <div className="admin-content">
                <Header />
                <div className="admin-body">
                    <div className="container">
                        <Breadcrumb title="Home" />

                        <div className="body-card">
                            <div className="heading">
                                <h2>Page Not Found</h2>
                            </div>

                            <div className="body-content">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateProps = (state) => ({
    menuState: state.menuState,
});

export default connect(mapStateProps, {})(Home);
