import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Footer = () => {
  const year = new Date();
  return (
    <div className="footer-wrapper">
      <Container>
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <div className="copyright">
              <p>
                &copy; All Rights Reserved {year.getFullYear()} - UpLift-U |{' '}
                <Link to="/privacy-policy"> Privacy Policy</Link>
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="mobile-footer-icons ">
              <div className="social-icons text-right">
                <ul>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
