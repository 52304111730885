import {
  GET_USERS,
  ADD_NEW_USER,
  UPDATE_USER,
  REMOVE_USER,
} from '../../Constants/Types';

export const users = (state = [], action = {}) => {
  let updatedUsers;
  switch (action.type) {
    case GET_USERS:
      return action.users;
    case ADD_NEW_USER:
      const oldUsers = state;
      oldUsers.unshift(action.user);
      return oldUsers;
    case UPDATE_USER:
      updatedUsers = state.map(user => {
        if (user._id === action.user._id) return {...user, ...action.user};
        return user;
      });
      return updatedUsers;
    case REMOVE_USER:
      updatedUsers = state.filter(user => {
        return user._id !== action.user._id;
      });
      return updatedUsers;
    default:
      return state;
  }
};
