import {
    PAGE_LOADED,
    UPADATE_WINDOW_WIDTH,
    SIDEBAR_MENU_STATE,
    GET_SETTINGS,    
    GET_NOTIFICATIONS,
} from "../../Constants/Types";

export const pageLoaded = (state = {}, action = {}) => {
    switch (action.type) {
        // Projects API
        case PAGE_LOADED:
            return action.status;
        default:
            return state;
    }
};
export const windowWidth = (state = 1200, action = {}) => {
    switch (action.type) {
        // Projects API
        case UPADATE_WINDOW_WIDTH:
            return action.width;
        default:
            return state;
    }
};

export const menuState = (state = false, action = {}) => {
    switch (action.type) {
        case SIDEBAR_MENU_STATE:
            return action.status;
        default:
            return state;
    }
};
export const settings = (state = {}, action = {}) => {
    switch (action.type) {
        case GET_SETTINGS:
            return action.settings;
        default:
            return state;
    }
};

export const notifications = (state = {}, action = {}) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return action.notifications;
        default:
            return state;
    }
};
