const Logo = require('../Assets/images/logo_white.png');
const LogoDark = require('../Assets/images/logo.png');
const Favicon = require('../Assets/images/favicon.png');
const userImage = require('../Assets/images/user-img.png');
const footerLogo = require('../Assets/images/logo.png');
const appleLogo = require('../Assets/images/social-apple.png');
const androidLogo = require('../Assets/images/social-android.png');
const bannerMobileImg = require('../Assets/images/banner-mobile-img.png');
const contentImg = require('../Assets/images/content-img.png');
const howWorks1 = require('../Assets/images/how-it-works-img-1.png');
const howWorks2 = require('../Assets/images/how-it-works-img-2.png');
const howWorks3 = require('../Assets/images/how-it-works-img-3.png');
const yellowCircle = require('../Assets/images/yellow-circle.png');

export {
  Logo,
  LogoDark,
  Favicon,
  userImage,
  footerLogo,
  appleLogo,
  androidLogo,
  bannerMobileImg,
  contentImg,
  howWorks1,
  howWorks2,
  howWorks3,
  yellowCircle,
};
