import axios from 'axios';
import {API_URL} from './env';

const postData = (url, data) => {
  return axios({
    method: 'POST',
    url: url,
    data,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  }).then(res => res.data);
};

const otherRequest = (method, url, data) => {
  return axios({
    method,
    url: url,
    data,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  }).then(res => res.data);
};

const getData = url => axios(url).then(res => res.data);

const API = {
  // Auth
  requestOtp: data => postData(`${API_URL}/login`, data),
  verifyOtp: data => postData(`${API_URL}/verify-otp`, data),
  getUserProfile: () => getData(`${API_URL}/get-profile`),
  saveUserProfile: data => postData(`${API_URL}/save-profile`, data),

  // Admin
  getDashboardData: () => getData(`${API_URL}/admin/dashboard`),

  importOrganizations: data =>
    postData(`${API_URL}/admin/import-organizations`, data),
  getOrganizations: data => postData(`${API_URL}/admin/organizations`, data),
  saveOrganization: data => postData(`${API_URL}/admin/organization`, data),
  updateOrganization: data =>
    otherRequest('PATCH', `${API_URL}/admin/organization`, data),
  removeOrganization: id =>
    otherRequest('DELETE', `${API_URL}/admin/organization?id=${id}`),

  getUsers: data => postData(`${API_URL}/admin/users`, data),
  addUser: data => postData(`${API_URL}/admin/user`, data),
  updateUser: data => otherRequest('PATCH', `${API_URL}/admin/user`, data),
  removeUser: id => otherRequest('DELETE', `${API_URL}/admin/user?id=${id}`),

  getCategories: page => getData(`${API_URL}/admin/categories/page/${page}`),
  addCategory: data => postData(`${API_URL}/admin/category`, data),
  updateCategory: data =>
    otherRequest('PATCH', `${API_URL}/admin/category`, data),
  removeCategory: id =>
    otherRequest('DELETE', `${API_URL}/admin/category?id=${id}`),

  getSettings: data => postData(`${API_URL}/admin/settings`, data),
  saveSettings: data => postData(`${API_URL}/admin/save-settings`, data),
  getNotifications: data => postData(`${API_URL}/admin/notifications`, data),
  saveNotifications: data =>
    postData(`${API_URL}/admin/save-notifications`, data),

  getAllCategories: () => getData(`${API_URL}/get-all-categories`),
  // getTermPages: () => getData(`${API_URL}/get-term-pages`),
  // saveTermPages: (data) => postData(`${API_URL}/save-term-pages`, data),

  // Front
};

export default API;
