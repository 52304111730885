/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Alert, Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
// import SocialIcons from "./SocialIcons";

import { ALLOWED_COUNTRY } from "../Utils/env";
import { registerOtpRequest, verifyOtp } from "../Store/Actions/AuthActions";
import VerifyPhone from "./VerifyPhone";
import { Link } from "react-router-dom";
import { appendFormData } from "../Utils/Helpers";

const inialData = {
    fname: "",
    phone: "",
    email: "",
    socials: "",
    address: "",
    state: "",
    region: "",
    city: "",
    zipcode: "",
};

const SignUpForm = ({ registerOtpRequest, verifyOtp, usStates }) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpState, changeOtpState] = useState(false);
    const [alertData, setAlertData] = useState(false);

    const [state, setState] = useState({
        errors: {},
        loading: false,
        data: inialData,
    });

    const setPhone = (phone) => {
        const data = { ...state.data, phone };
        setState({
            ...state,
            data,
        });
    };
    const onChange = (e) => {
        const value = e.target.value;
        const data = { ...state.data, [e.target.name]: value };

        setState({
            ...state,
            data,
        });
    };
    const updatePhone = () => {
        setPhone("");
        setOtp("");
        changeOtpState(false);
    };
    const onStateChange = (e) => {
        const { data } = state;
        const value = e.target.value;

        const stateData = getRegion(value);
        const region = stateData.region.name;

        setState({
            ...state,
            data: {
                ...data,
                ["state"]: value,
                ["region"]: region,
            },
        });
    };
    const getRegion = (stateId) => {
        const state = usStates.filter((item) => item.id === stateId);

        return state ? state[0] : {};
    };

    const validateData = () => {
        const data = { ...state.data };
        const tempErr = {};

        if (data.fname === "") {
            tempErr.fname = "Please enter your Name.";
        }
        if (!data.phone || data.phone === "") {
            tempErr.phone = "Please enter your Phone.";
        }
        // if (!data.address || data.address === "") {
        //     tempErr.address = "Please enter your address.";
        // }
        if (!data.state || data.state === "") {
            tempErr.state = "Please choose state.";
        }
        // if (!data.city || data.city === "") {
        //     tempErr.city = "Please enter your City.";
        // }
        // if (!data.zipcode || data.zipcode === "") {
        //     tempErr.zipcode = "Please enter your zipcode.";
        // }

        return tempErr;
    };
    const submitForm = (event) => {
        event.preventDefault();

        const errs = validateData();

        if (!loading) {
            if (Object.entries(errs).length === 0) {
                const data = { ...state.data };
                setLoading(true);
                setErrors({});

                const formData = appendFormData(data);

                registerOtpRequest(formData)
                    .then((res) => {
                        if (res.type === "success") {
                            const networkOtp = res.otp ? res.otp : "";
                            setOtp(networkOtp);
                            changeOtpState(true);
                        }

                        const type = res.type === "error" ? "danger" : res.type;
                        setAlertData({
                            show: true,
                            type,
                            message: res.message,
                        });

                        setLoading(false);
                    })
                    .catch((err) => {
                        setLoading(false);
                        setAlertData({
                            show: true,
                            type: "danger",
                            message: err.message,
                        });
                    });
            } else {
                setErrors(errs);
            }
        }
        return false;
    };

    const resetForm = () => {
        setState({
            ...state,
            data: inialData,
        });
    };

    const { data } = state;

    return (
        <div className="auth-form">
            {alertData.show && (
                <Alert variant={alertData.type} onClose={() => setAlertData({ show: false })} dismissible>
                    <p>{alertData.message}</p>
                </Alert>
            )}
            {!otpState && (
                <Form onSubmit={submitForm}>
                    <Form.Group className="form-group">
                        <Form.Control
                            name="fname"
                            value={data.fname}
                            placeholder="Name"
                            onChange={(e) => onChange(e)}
                        />
                        {errors.fname && <span className="errorMsg">{errors.fname}</span>}
                    </Form.Group>
                    <Form.Group className="form-group">
                        <PhoneInput
                            name="phone"
                            className="form-control"
                            onlyCountries={ALLOWED_COUNTRY}
                            country={"us"}
                            value={data.phone}
                            onChange={(phone) => setPhone(phone)}
                        />
                        {errors.phone && <span className="errorMsg">{errors.phone}</span>}
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Control
                            name="email"
                            value={data.email}
                            placeholder="Email"
                            onChange={(e) => onChange(e)}
                        />
                        {errors.email && <span className="errorMsg">{errors.email}</span>}
                    </Form.Group>

                    <Row>
                        <Col>
                            <Form.Group className="form-group" controlId="state">
                                <div className="select-dropdown">
                                    <select
                                        name="state"
                                        value={data.state ? data.state : ""}
                                        onChange={(e) => onStateChange(e)}
                                        className="form-control"
                                    >
                                        <option value="">Choose State</option>
                                        {Object.entries(usStates).length > 0 &&
                                            usStates.map((item, index) => (
                                                <option value={item.id} key={`state_index_${index}`}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                {errors.state && <span className="errorMsg">{errors.state}</span>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="form-group" controlId="region">
                                <Form.Control
                                    name="region"
                                    type="text"
                                    placeholder="Region"
                                    value={data.region ? data.region : ""}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {/*

                    <Form.Group className="form-group" controlId="address">
                        <Form.Control
                            name="address"
                            type="text"
                            placeholder="Address"
                            value={data.address ? data.address : ""}
                            onChange={(e) => onChange(e)}
                        />
                        {errors.address && <span className="errorMsg">{errors.address}</span>}
                    </Form.Group>

                    <Row>
                        <Col>
                            <Form.Group className="form-group" controlId="city">
                                <Form.Control
                                    name="city"
                                    type="text"
                                    placeholder="City"
                                    value={data.city ? data.city : ""}
                                    onChange={(e) => onChange(e)}
                                />
                                {errors.city && <span className="errorMsg">{errors.city}</span>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="form-group" controlId="zipcode">
                                <Form.Control
                                    name="zipcode"
                                    type="text"
                                    placeholder="Zipcode"
                                    value={data.zipcode ? data.zipcode : ""}
                                    onChange={(e) => onChange(e)}
                                />
                                {errors.zipcode && <span className="errorMsg">{errors.zipcode}</span>}
                            </Form.Group>
                        </Col>
                    </Row> */}

                    {/* <SocialIcons icons={socials} updateIcons={(icons) => setSocials(icons)} /> */}

                    <Form.Group className="form-group mb-0">
                        <button className="btn btn-small" onClick={() => submitForm}>
                            {!loading && "Signup"}
                            {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                        </button>

                        <div className="signup-links">
                            Already Registered? <Link to="/login">Login Now</Link>
                        </div>
                    </Form.Group>
                </Form>
            )}

            {otpState && (
                <VerifyPhone
                    otp={otp}
                    newUser={data}
                    phone={data.phone}
                    showAlert={(type, message) => setAlertData({ show: true, type, message })}
                    resetForm={() => resetForm()}
                    updatePhone={() => updatePhone()}
                    verifyOtp={(data) => verifyOtp(data)}
                />
            )}
        </div>
    );
};

const mapStateProps = (state) => ({
    usStates: state.usStates,
});

export default connect(mapStateProps, { registerOtpRequest, verifyOtp })(SignUpForm);
