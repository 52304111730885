/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
// import Moment from "react-moment";
import {Alert} from 'react-bootstrap';

import {updateUrl} from '../../Utils/Helpers';

import {getUsers, removeUser} from '../../Store/Actions/UsersAction';
import {Breadcrumb, AddUser, EditUser} from '../../Components';

const Users = props => {
  const page = props.match.params.page ? props.match.params.page : 1;
  const {getUsers, removeUser, users} = props;

  const [state, setState] = useState({
    add_user_modal: false,
    edit_user_modal: false,
    edit_user: {},
    listLoading: false,
    page,
    bulk_action_id: [],
    pagging: {},
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  });

  const [usersList, setUsers] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const toggleModal = (modal, modalState) => {
    setState({
      ...state,
      [modal]: modalState,
    });
  };

  const enableEditModal = user => {
    setState({
      ...state,
      edit_user_modal: true,
      edit_user: user,
    });
  };

  const fetchUsers = page => {
    setState({
      ...state,
      listLoading: true,
    });

    getUsers({data: {page}})
      .then(res => {
        const newState = {
          ...state,
          listLoading: false,
        };

        if (res.type === 'success') {
          const newUrl =
            parseInt(res.pagging.current) === 1
              ? `/admin/users`
              : `/admin/users/page/${page}`;

          updateUrl(newUrl, props.history);
          newState.pagging = res.pagging;
          newState.page = res.pagging.current;
        }

        setState(newState);
      })
      .catch(err => {
        console.log(err);
        setState({
          ...state,
          listLoading: false,
          alertData: {
            show: false,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  const deleteUser = id => {
    if (!deleteLoading) {
      setDeleteLoading(true);
      setDeleteId(id);

      removeUser(id)
        .then(res => {
          setDeleteLoading(false);
          setDeleteId('');

          setState({
            ...state,
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        })
        .catch(err => {
          setDeleteLoading(false);
          setDeleteId('');

          setState({
            ...state,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  const toggleAllBulk = e => {
    let {bulk_action_id} = state;

    if (e.target.checked) {
      users.forEach(item => {
        if (bulk_action_id.indexOf(`${item.id}`) === -1) {
          bulk_action_id.push(`${item.id}`);
        }
      });
    } else {
      bulk_action_id = [];
    }

    setState({
      ...state,
      bulk_action_id,
    });
  };

  const toggleBulkItems = e => {
    let {bulk_action_id} = state;

    if (e.target.checked) {
      if (bulk_action_id.indexOf(`${e.target.value}`) === -1) {
        bulk_action_id.push(`${e.target.value}`);
      }
    } else {
      bulk_action_id = bulk_action_id.filter(
        (item, index) => `${item}` !== `${e.target.value}`,
      );
    }

    setState({
      ...state,
      bulk_action_id,
    });
  };

  const _renderItems = user => {
    const userStatus = user.status === 'Active' ? 'online' : 'offline';

    return (
      <tr key={`users_key_${user._id}`}>
        <td align="center">
          <input
            type="checkbox"
            name="bulk_action[]"
            value={user.id}
            checked={
              bulk_action_id.indexOf(`${user._id}`) !== -1 ? true : false
            }
            onChange={e => toggleBulkItems(e)}
          />
        </td>
        <td>{user.name}</td>
        <td>{user.phone}</td>
        <td>{user.email}</td>
        <td>{user.user_type}</td>
        <td>{String(user.auth_type).toLocaleUpperCase()}</td>
        <td>
          <span className={`status ${userStatus}`}>
            {user.status ? user.status : 'Inactive'}
          </span>{' '}
        </td>

        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span className="actionIcon" onClick={() => enableEditModal(user)}>
              <i className="fas fa-edit" />
            </span>
            <span
              className="actionIcon danger"
              onClick={() => deleteUser(user._id)}>
              {deleteLoading && deleteId === user._id && (
                <i className="fas fa-spin fa-spinner" />
              )}
              {deleteId !== user._id && <i className="fas fa-trash" />}
            </span>
          </div>
        </td>
      </tr>
    );
  };

  const hideErrMsg = () => {
    setState({
      ...state,
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  useEffect(() => {
    fetchUsers(page);
  }, [page]);

  useEffect(() => {
    if (users) {
      setUsers(users);
    }
  }, [users]);

  const {
    add_user_modal,
    edit_user_modal,
    edit_user,
    bulk_action_id,
    pagging,
    alertData,
    listLoading,
  } = state;

  return (
    <div className="admin-body">
      <div className="container">
        <Breadcrumb title="Users" />

        <div className="body-card">
          <div className="heading">
            <h2>Users</h2>
            <div className="filter-options-wrapper">
              <span
                className="btn btn-primary btn-small add-new-btn"
                onClick={() => {
                  toggleModal('add_user_modal', true);
                }}>
                Add New
              </span>
            </div>
          </div>

          <div className="body-content">
            <div className="cctv-cotent-wrapper">
              <div className="listing-tables mt-0">
                {alertData && alertData.show && (
                  <div className="alerts">
                    <Alert
                      variant={alertData.type}
                      onClose={() => hideErrMsg()}
                      dismissible>
                      {alertData.message}
                    </Alert>
                  </div>
                )}

                <table className="table table-inverse table-responsive">
                  <thead className="thead-inverse">
                    <tr>
                      <th className="text-center">
                        <input
                          type="checkbox"
                          id="chkAll"
                          onChange={e => {
                            toggleAllBulk(e);
                          }}
                        />
                      </th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Auth Type</th>
                      <th width="80" className="text-center">
                        Status
                      </th>
                      {/* <th width="150">Date</th> */}
                      <th width="130" className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {listLoading && (
                      <tr>
                        <td align="center" colSpan={8}>
                          <span className="fas fa-spinner fa-spin" />
                        </td>
                      </tr>
                    )}
                    {!listLoading && Object.entries(usersList).length === 0 && (
                      <tr>
                        <td align="center" colSpan={8}>
                          No Users Available.
                        </td>
                      </tr>
                    )}
                    {state &&
                      !listLoading &&
                      Object.entries(usersList).length > 0 &&
                      usersList.map(user => _renderItems(user))}
                  </tbody>
                </table>

                {pagging && (
                  <div className="pagging-wrapper">
                    {pagging.current && pagging.pages && pagging.totalItems && (
                      <div className="pagging-text">
                        <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                      </div>
                    )}

                    <div className="pagging-btns">
                      {pagging.previous && (
                        <span
                          className="btn btn-small"
                          onClick={() => fetchUsers(pagging.previous)}>
                          Previous
                        </span>
                      )}
                      {pagging.next && (
                        <span
                          className="btn btn-small next-btn"
                          onClick={() => fetchUsers(pagging.next)}>
                          Next
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {add_user_modal && (
            <AddUser
              show={add_user_modal}
              toggleModal={(modal, modalState) =>
                toggleModal(modal, modalState)
              }
            />
          )}
          {edit_user_modal && (
            <EditUser
              show={edit_user_modal}
              user={edit_user}
              toggleModal={(modal, modalState) =>
                toggleModal(modal, modalState)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  users: state.users,
});

export default connect(mapStateProps, {getUsers, removeUser})(Users);
