import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AuthRoute = ({ isAuthenticated, currentUser, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated && currentUser.user_type === "Administrator" ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

const mapStateProp = (state) => ({
    currentUser: state.user,
    isAuthenticated: !!state.user.authToken,
});

export default connect(mapStateProp)(AuthRoute);
