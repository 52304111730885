import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-phone-input-2/lib/style.css';

import './Assets/css/admin/style.css';
import './Assets/css/admin/responsive.css';
import './Assets/css/style.css';
import './Assets/css/responsive.css';

import store from './Store';
import App from './Routes';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
