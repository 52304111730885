import React, {useState} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FaqItems} from '../Components';
import {LogoDark, appleLogo, androidLogo} from '../Constants/Images';
import Footer from './Footer';
import Header from './Header';

const LandingPage = ({params}) => {
  return (
    <div className="landing-page-wrapper">
      <Header />

      {/* <div className="banner-wrapper">
        <Container>
          <Row className="row-reverse">
            <Col md={7} xs={12}>
              <div className="banner-img">
                <img src={bannerMobileImg} alt="" />
              </div>
            </Col>
            <Col md={5} xs={12}>
              <div className="banner-content">
                <h1>
                  Get the <span>Most</span>
                  {` `}
                  Out of Life
                </h1>
                <p>Find support while capturing your journey</p>
                {downloadblock()}
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      <div className="content-page-wrapper">
        <Container>
          <Row>
            <Col md={12} xs={12}>
              <div className="conent-page">
                <h2>Privacy Policy</h2>
                {/* <p>Cookies Disclaimer: typically displayed on the homepage</p> */}
                <ul>
                  <li>
                    {' '}
                    This website intends to use cookies to provide a quality
                    user experience. By continuing to use this website, you
                    confirm your awareness and acceptance of our use of cookies,
                    which is in accordance with our Terms of Use (hyperlink) and
                    Privacy Policy (hyperlink).
                    <ul>
                      <li>
                        Note: Your Happiness already has a cookies disclaimer on
                        its privacy page, but most apps (and websites) have a
                        short disclaimer on their homepage.
                      </li>
                    </ul>
                  </li>
                </ul>

                <p>
                  Emergency/Crisis Disclaimer: since the app targets users
                  addressing mental health, there should be a disclosure or
                  resource that provides information for life-threatening
                  situations – ideally as a header or footer on each page
                </p>

                <ul>
                  <li>
                    {' '}
                    If you or any other person are in a crisis or life
                    threatening situation, do not use this website. Call 911,
                    1-800-273-8255 (National Suicide Prevent Lifeline), or use
                    these resources (hyperlink to the below resources).:
                    <ul>
                      <li>
                        {' '}
                        National Domestic Violence Hotline: 1- 800-799-7233
                      </li>
                      <li> Family Violence Helpline: 1-800-996-6628</li>
                      <li>
                        {' '}
                        National Hopeline Network: 1-800-SUICIDE (800-784-2433)
                      </li>
                      <li>
                        {' '}
                        Crisis Text Line: Text &quot;DESERVE&quot; TO 741-741
                      </li>
                      <li>
                        {' '}
                        Lifeline Crisis Chat (Online live messaging):
                        https://suicidepreventionlifeline.org/chat/
                      </li>
                      <li>
                        {' '}
                        Self-Harm Hotline: 1-800-DONT CUT (1-800-366-8288)
                      </li>
                      <li>
                        {' '}
                        Essential local and community services: 211,
                        https://www.211.org/
                      </li>
                      <li>
                        {' '}
                        Planned Parenthood Hotline: 1-800-230-PLAN (7526)
                      </li>
                      <li>
                        {' '}
                        American Association of Poison Control Centers:
                        1-800-222-1222
                      </li>
                      <li>
                        {' '}
                        National Council on Alcoholism &amp; Drug Dependency
                        Hope Line: 1-800-622-2255
                      </li>
                      <li>
                        {' '}
                        National Crisis Line - Anorexia and Bulimia:
                        1-800-233-4357
                      </li>
                      <li> GLBT Hotline: 1-888-843-4564</li>
                      <li> TREVOR Crisis Hotline: 1-866-488-7386</li>
                      <li> AIDS Crisis Line: 1-800-221-7044</li>
                      <li>
                        {' '}
                        Veterans Crisis Line: https://www.veteranscrisisline.net
                      </li>
                      <li>
                        {' '}
                        TransLifeline: https://www.translifeline.org -
                        877-565-8860
                      </li>
                      <li>
                        {' '}
                        The Childhelp National Child Abuse Hotline:
                        1-800-422-4453
                      </li>
                      <li>
                        {' '}
                        Suicide Prevention Wiki:
                        http://suicideprevention.wikia.com
                      </li>
                    </ul>
                  </li>
                </ul>

                <p>
                  Consent: suggest this being displayed in between the “Enter
                  Your Zip Code” and “FIND HELP NOW” tabs
                </p>

                <ul>
                  <li>
                    By clicking below, you confirm you are [minimum age] and
                    accept our Terms of Use (hyperlink) and Privacy Policy
                    (hyperlink). If you do not agree to be bound to the Terms of
                    Use or Privacy Policy you should immediately exit this
                    website.
                  </li>
                </ul>

                <h2>Terms of Use or Terms &amp; Conditions:</h2>
                <ul>
                  <li>
                    Please read these Terms of Use carefully
                    (&quot;Terms&quot;). These Terms govern and apply to your
                    access and use of this communication platform offered by
                    Wilcon Enterprises, LLC or Your Happiness (“We” or “Us”). By
                    accessing or using this website, you agree to comply with
                    and to be bound by all of the terms and conditions described
                    in these Terms. If you do not agree to all of these terms
                    and conditions, you are not authorized to use this website.
                    The terms “You&quot;, &quot;Your,” and “Yourself” refer to
                    the individual user of this website.
                  </li>
                  <li>
                    By using this website you affirm that you are (i) over the
                    age of eighteen (18) or [if minors are allowed] have the
                    legal ability to consent to therapy or have your
                    parent/guardian consent to therapy.
                  </li>
                  <li>
                    The resources and other incidental information on this
                    website is not and should not be considered medical advice.
                    You should always talk to an appropriately qualified health
                    care professional for diagnosis, medication, and treatment
                    that may be appropriate for you.
                  </li>
                  <li>
                    We do not employ or endorse the health care professionals
                    that may be listed on this website. We have chosen the
                    listed health care professionals based on [state the
                    selection criteria – see examples below]:
                    <ul>
                      <li>Licenses/Awards/Ratings</li>
                      <li>
                        Quality assurance organizations (e.g. National Committee
                        for Quality Assurance) 
                      </li>
                      <li>Mental health organizations</li>
                      <li>Etc.</li>
                    </ul>
                  </li>
                  <li>
                    All of the content available on or through this website is
                    the property of Wilcon Enterprises, LLC, Your Happiness, or
                    its licensors and is protected by copyright, trademark,
                    patent, trade secret and other intellectual property law.
                    You agree not to reproduce, retransmit, distribute,
                    disseminate, sell, publish, broadcast, or circulate the
                    content received through the Service to any third party. Any
                    copy of information obtained through the Service must
                    include all applicable copyright notices.
                  </li>
                  <li>
                    Access to this website and the information contained therein
                    is provided “as is” and “as available” without any warranty,
                    express or implied. We disclaim all warranties, express or
                    implied. In the event of any problem with this website or
                    any of its content, you agree that your sole remedy is to
                    cease using the website. We shall not be liable under any
                    circumstances for your use or misuse of this website,
                    including but not limited to, punitive, consequential,
                    incidental, indirect, and special damages, arising from or
                    in connection with your use of this website.
                  </li>
                  <li>
                    Any link (including a hyperlink, button or referral device
                    of any kind) used in this website is provided for your use
                    and convenience. The appearance of a link does not
                    constitute an endorsement, recommendation, or certification.
                    Additionally, the presence of a link does not suggest that
                    any third party website is an affiliate of Wilcon
                    Enterprises, LLC or Your Happiness.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
