import React, {useState} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';

import {FaqItems} from '../Components';
import {
  appleLogo,
  androidLogo,
  bannerMobileImg,
  contentImg,
  howWorks1,
  howWorks2,
  howWorks3,
  yellowCircle,
} from '../Constants/Images';
import Footer from './Footer';
import Header from './Header';

const LandingPage = ({params}) => {
  const [currentFaqItem, setFaqItem] = useState(1);

  const downloadblock = () => {
    return (
      <div className="download-btn-wrapper">
        <Button>Download Uplift-U</Button>
        <span className="social-icon">
          <a href="#" target="_blank">
            <img src={appleLogo} alt="" />
          </a>
        </span>
        <span className="social-icon">
          <a href="#" target="_blank">
            <img src={androidLogo} alt="" />
          </a>
        </span>
      </div>
    );
  };
  const year = new Date();
  return (
    <div className="landing-page-wrapper">
      <Header />
      <div className="banner-wrapper">
        <Container>
          <Row className="row-reverse">
            <Col md={7} xs={12}>
              <div className="banner-img">
                <img src={bannerMobileImg} alt="" />
              </div>
            </Col>
            <Col md={5} xs={12}>
              <div className="banner-content">
                <h1>
                  Get the <span>Most</span>
                  {` `}
                  Out of Life
                </h1>
                <p>Find support while capturing your journey</p>
                {downloadblock()}
              </div>
            </Col>
          </Row>
          <div className="circle-img">
            <img src={yellowCircle} alt="" />
          </div>
        </Container>
      </div>

      <div className="about-wrapper">
        <Container>
          <Row>
            <Col md={6} xs={12}>
              <div className="about-img">
                <img src={contentImg} alt="" />
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="about-content">
                <h2>Redefine Your Path</h2>
                <p>
                  Easily document your feelings and reactions to dive deeper in
                  understanding trends and triggers.
                </p>
                {downloadblock()}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="how-it-works-wrapper">
        <Container>
          <div className="headings">
            <h2>How it Works</h2>
            <p>
              Easily capture your feelings and reactions to dive deeper in
              understanding trends and triggers.
            </p>
          </div>
          <div className="how-it-works-steps">
            <Row>
              <Col md={4} xs={12}>
                <div className="how-it-works-item">
                  <img src={howWorks1} alt="" />
                  <h2>Establish Habbits</h2>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="how-it-works-item">
                  <img src={howWorks3} alt="" />
                  <h2>Capture Moments</h2>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="how-it-works-item">
                  <img src={howWorks2} alt="" />
                  <h2>Connect with Resources</h2>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="faq-wrapper">
        <Container>
          <div className="headings">
            <h2>FAQ</h2>
          </div>
          <FaqItems
            current={currentFaqItem}
            setActiveFaq={index => setFaqItem(index)}
          />
        </Container>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
