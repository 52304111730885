import {
  faUsers,
  faCog,
  faChartBar,
  faRibbon,
  faCertificate,
  faHome,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';

export const menuItems = [
  {
    label: 'Dashboard',
    link: '/admin/dashboard',
    icon: faChartBar,
    submenu: [],
  },
  {
    label: 'Organizations',
    link: '/admin/organizations',
    icon: faRibbon,
    submenu: [],
  },
  // {
  //     label: "Categories",
  //     link: "/admin/categories",
  //     icon: faCertificate,
  //     submenu: [],
  // },
  {
    label: 'Users',
    link: '/admin/users',
    icon: faUsers,
    submenu: [],
  },
  // {
  //     label: "Pages",
  //     link: "/admin/pages-content",
  //     icon: faFileAlt,
  //     submenu: [],
  // },
  {
    label: 'Notifications',
    link: '/admin/notificatoins',
    icon: faEnvelope,
    submenu: [],
  },
  {
    label: 'Settings',
    link: '/admin/settings',
    icon: faCog,
    submenu: [],
  },
];

export const GuestMenuItems = [
  {
    label: 'Home',
    link: '/',
    icon: faHome,
    submenu: [],
  },
  {
    label: 'Login',
    link: '/login',
    icon: faLock,
    submenu: [],
  },
];
