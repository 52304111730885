/* eslint-disable react/jsx-no-target-blank */
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";

// import { AuthMenuItems, GuestMenuItems } from "../Constants/Menu";
import { logout } from "../Store/Actions/AuthActions";
import Profile from "./Admin/SidebarMenu/Profile";

const SidebarMenu = (props) => {
    const { location, menuState, logout, isLogin, user } = props;

    const _activeCheck = (item) => {
        let isActive = false;
        const currentPage = location.pathname;

        if (currentPage === item.link) {
            isActive = true;
        }

        if (item.submenu && Object.entries(item.submenu).length > 0) {
            for (let i = 0; i < Object.entries(item.submenu).length; i++) {
                const submenuItem = item.submenu[i];

                if (currentPage === submenuItem.link) {
                    isActive = true;
                }
            }
        }

        return isActive;
    };

    const _toggleSubmenu = (index) => {
        const submenuLi = document.getElementById(`parent_menu_${index}`);
        if (submenuLi.classList.contains("open")) {
            submenuLi.classList.remove("open");
        } else {
            submenuLi.classList.add("open");
        }
    };

    const _renderSubMenuItem = (item, index) => {
        const isActive = _activeCheck(item) ? "active" : "";
        return (
            <li key={`submenu_key_${index}`} className={isActive}>
                {item.link !== "" && item.isExternal && (
                    <a href={item.link} target="_blank">
                        {item.icon && <FontAwesomeIcon icon={item.icon} className="icon" />}
                        <span className="menu-label">{item.label}</span>
                    </a>
                )}
                {item.link !== "" && !item.isExternal && (
                    <Link to={item.link}>
                        {item.icon && <FontAwesomeIcon icon={item.icon} className="icon" />}
                        <span className="menu-label">{item.label}</span>
                    </Link>
                )}
            </li>
        );
    };

    const _renderMenuItem = (item, index) => {
        let isActive = _activeCheck(item) ? "active" : "";
        isActive += item.link === "" ? " withPadding" : "";
        isActive += Object.entries(item.submenu).length > 0 ? " has-child as-link" : "";
        return (
            <li id={`parent_menu_${index}`} key={`menu_key_${index}`} className={isActive}>
                <span className="menu-text" onClick={() => _toggleSubmenu(index)}>
                    {item.link !== "" && item.isExternal && (
                        <a href={item.link} target="_blank">
                            {item.icon && <FontAwesomeIcon icon={item.icon} className="icon" />}
                            <span className="menu-label">{item.label}</span>
                        </a>
                    )}
                    {item.link !== "" && !item.isExternal && (
                        <Link to={item.link}>
                            {item.icon && <FontAwesomeIcon icon={item.icon} className="icon" />}
                            <span className="menu-label">{item.label}</span>
                        </Link>
                    )}
                    {item.link === "" && (
                        <>
                            {item.icon && <FontAwesomeIcon icon={item.icon} className="icon" />}
                            <span className="menu-label">{item.label}</span>
                        </>
                    )}
                    {Object.entries(item.submenu).length > 0 && <span className="submenu-icon" />}
                </span>
                {Object.entries(item.submenu).length > 0 && (
                    <ul className="sub-menu">
                        {item.submenu.map((subItem, index) => _renderSubMenuItem(subItem, index))}
                    </ul>
                )}
            </li>
        );
    };

    const renderDashboardMenu = () => {
        if (user.user_type === "Administrator") {
            return (
                <li>
                    <Link to="/admin">
                        <FontAwesomeIcon icon={faChartBar} className="icon" />
                        <span className="menu-label">Dashboard</span>
                    </Link>
                </li>
            );
        }
    };

    const d = new Date();
    const currentYear = d.getFullYear();
    const menuStatus = menuState ? "open" : "";

    return (
        <div className={`sidebar-menu-wrapper ${menuStatus}`}>
            <Profile />
            <div className="sidebar-navigation">
                {/* {isLogin && Object.entries(AuthMenuItems).length > 0 && ( */}
                    <ul>
                        {renderDashboardMenu()}
                        {/* {AuthMenuItems.map((item, index) => _renderMenuItem(item, index))} */}
                    </ul>
                {/* )} */}
                {/* {!isLogin && <ul>{GuestMenuItems.map((item, index) => _renderMenuItem(item, index))}</ul>} */}
            </div>
            <div className="sidebar-footer">
                <span className="menu-text">
                    {isLogin && (
                        <span className="as-link" onClick={() => logout()}>
                            <span className="icon fas fa-sign-out-alt" />
                            <span className="menu-label">Logout</span>
                        </span>
                    )}
                    <div className="copyright-text">
                        <p>Copyright &copy;{currentYear}</p>
                    </div>
                </span>
            </div>
        </div>
    );
};

const mapStateProps = (state) => ({
    menuState: state.menuState,
    isLogin: !!state.user.authToken,
    user: state.user,
});

export default connect(mapStateProps, { logout })(SidebarMenu);
