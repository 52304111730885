import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { ALLOWED_COUNTRY } from "../Utils/env";
import { requestOtp, verifyOtp } from "../Store/Actions/AuthActions";
import VerifyPhone from "./VerifyPhone";

const LoginForm = ({ requestOtp, verifyOtp }) => {
    const [loading, setLoading] = useState(false);
    const [otpState, changeOtpState] = useState(false);    
    const [data, setData] = useState({
        phone: "",
        otp: "",
        device_type: "web",
        device_id: ""
    });

    const [alertData, setAlertData] = useState({
        show: false,
        type: '',
        message: ""
    });

    const setOtp = (otp) => {

        setData({
            ...data,
            otp
        });

    }

    const toggleAlert = (type, message, show = true,) => {
        setAlertData({
            show,
            type,
            message
        });
    };

    const onChange = (field, value) => {

        const tempData = {
            ...data,
            [field]: value,
        };
        setData(tempData);

    }

    const submitLogin = (event) => {
        const tempData = { ...data };
        event.preventDefault();
        setLoading(true);

        tempData.phone = `+${tempData.phone}`;

        requestOtp({ data: tempData })
            .then((res) => {
                if (res.type === "success") {
                    changeOtpState(true);
                    const networkOtp = res.code ? res.code : "";
                    setOtp(networkOtp);                    
                }

                const type = res.type === "error" ? "danger" : res.type;
                toggleAlert(type, res.message);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toggleAlert("danger", err.message);
            });

        return false;
    };

    const updatePhone = () => {

        setData({
            ...data,
            phone:"",
            otp:"",
        });
        
        changeOtpState(false);
    };    

    return (
        <div className="auth-form">
            {alertData.show && (
                <Alert variant={alertData.type} onClose={() => toggleAlert('', '', false)} dismissible>
                    <p>{alertData.message}</p>
                </Alert>
            )}

            {!otpState && (
                <Form onSubmit={submitLogin}>
                    <Form.Group className="form-group">
                        <PhoneInput
                            className="form-control"
                            onlyCountries={ALLOWED_COUNTRY}
                            country={"us"}
                            value={data.phone}
                            onChange={(phone) => onChange("phone", phone)}
                        />
                    </Form.Group>

                    <Form.Group className="form-group mb-0">
                        <button className="btn btn-small" onClick={submitLogin}>
                            {!loading && "Login"}
                            {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                        </button>
                    </Form.Group>

                    {/* <div className="signup-links">
                        Don't have account? <Link to="/register">Register Now</Link>
                    </div> */}
                </Form>
            )}

            {otpState && (
                <VerifyPhone                     
                    data={data}
                    showAlert={(type, message) => toggleAlert(type, message)}
                    updatePhone={() => updatePhone()}
                    verifyOtp={(data) => verifyOtp(data)}
                />
            )}
        </div>
    );
};

export default connect(null, { requestOtp, verifyOtp })(LoginForm);
