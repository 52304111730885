import {     
    GET_ORAGANIZATIONS,
    ADD_NEW_ORGANIZATION,
    UPDATE_ORGANIZATION,
    REMOVE_ORGANIZATION,   
    SAVE_TERM_PAGES,
} from "../../Constants/Types";

export const organizations = (state = [], action = {}) => {
    let items;
    switch (action.type) {
        case GET_ORAGANIZATIONS:
            return action.items;
        case ADD_NEW_ORGANIZATION:
            items = state;
            console.log(1);
            if (Object.entries(items).length > 0) {
                console.log(2);
                items.unshift(action.item);
            } else {
                console.log(3);
                items = items.push(action.item);
            }
            console.log("New Items :",items);
            return items;
        case UPDATE_ORGANIZATION:
            items = state.map((item) => {
                if (item._id === action.item._id) return { ...item, ...action.item };
                return item;
            });
            return items;
        case REMOVE_ORGANIZATION:
            items = state.filter((item) => {
                return item._id !== action.item._id;
            });
            return items;
        default:
            return state;
    }
};

export const termPages = (state = [], action = {}) => {
    let pages;
    switch (action.type) {
        case SAVE_TERM_PAGES:
            return action.pages;        
        default:
            return state;
    }
};
