export const PAGE_LOADED = 'PAGE_LOADED';
export const UPADATE_WINDOW_WIDTH = 'UPADATE_WINDOW_WIDTH';
export const GET_STATES = 'GET_STATES';

// Auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Admin
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SIDEBAR_MENU_STATE = 'SIDEBAR_MENU_STATE';

export const GET_ORAGANIZATIONS = 'GET_ORAGANIZATIONS';
export const ADD_NEW_ORGANIZATION = 'ADD_NEW_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const REMOVE_ORGANIZATION = 'REMOVE_ORGANIZATION';

export const GET_USERS = 'GET_USERS';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';

export const SAVE_TERM_PAGES = 'SAVE_TERM_PAGES';
export const GET_TERM_PAGES = 'GET_TERM_PAGES';
