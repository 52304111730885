import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const VerifyPhone = ({ data, showAlert, updatePhone,  verifyOtp }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [tempOtp,setTempOtp] = useState({
        num1:'',
        num2:'',
        num3:'',
        num4:'',
        num5:'',
        num6:'',
    });   

    const validateData = () => {
        const tempErr = {};
        const tempNewOtp = `${tempOtp.num1}${tempOtp.num2}${tempOtp.num3}${tempOtp.num4}${tempOtp.num5}${tempOtp.num6}`;

        if (tempNewOtp === "" || tempNewOtp.length !== 6) {
            tempErr.otp = "Please enter valid OTP.";
        }

        return tempErr;
    };

    const submitForm = (e) => {
        e.preventDefault();
        const errs = validateData();

        if (!loading && Object.entries(errs).length === 0) {
            setLoading(true);
            
            const tempData = {...data};
            tempData.phone = `+${tempData.phone}`;            
            tempData.code = `${tempOtp.num1}${tempOtp.num2}${tempOtp.num3}${tempOtp.num4}${tempOtp.num5}${tempOtp.num6}`;

            console.log("Verify OTP: ",tempData);            

            try {
                verifyOtp({data:tempData})
                    .then((res) => {
                        const type = res.type === "error" ? "danger" : res.type;

                        showAlert(type, res.message);
                        setLoading(false);
                        updatePhone();
                        
                    })
                    .catch((err) => {
                        showAlert("danger", err.message);
                        setLoading(false);
                    });
            } catch (err) {
                showAlert("danger", err.message);
            }
        } else {
            setErrors(errs);
        }

        return false;
    };

    const updateNumber = (e, target) => {
        const number = e.target.value;

        setTempOtp({
            ...tempOtp,
            [`num${target}`]:number
        });

        switch (target) {
            case "1":                
                if (number) {
                    document.getElementById("num-2").focus();
                }
                break;
            case "2":                
                if (number) {
                    document.getElementById("num-3").focus();
                }
                break;
            case "3":                
                if (number) {
                    document.getElementById("num-4").focus();
                }
                break;
            case "4":                
                if (number) {
                    document.getElementById("num-5").focus();
                }
                break;
            case "5":                
                if (number) {
                    document.getElementById("num-6").focus();
                }
                break;
            default:                
                break;
        }
    };

    useEffect(() => {
        if (data.otp) {
            const number = `${data.otp}`;
            setTempOtp({
                num1:number.charAt(0),
                num2:number.charAt(1),
                num3:number.charAt(2),
                num4:number.charAt(3),
                num5:number.charAt(4),
                num6:number.charAt(5),
            })
        }
    }, [data.otp]);

    return (
        <div className="verify-phone-wrapper">
            <Form onSubmit={submitForm}>
                <Form.Label>OTP</Form.Label>
                <div className="otp-wrapper">
                    <Form.Group className="form-group">
                        <Form.Control id="num-1" maxLength={1} value={tempOtp.num1} onChange={(e) => updateNumber(e, "1")} />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Control id="num-2" maxLength={1} value={tempOtp.num2} onChange={(e) => updateNumber(e, "2")} />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Control
                            id="num-3"
                            maxLength={1}
                            value={tempOtp.num3}
                            onChange={(e) => updateNumber(e, "3")}
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Control id="num-4" maxLength={1} value={tempOtp.num4} onChange={(e) => updateNumber(e, "4")} />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Control id="num-5" maxLength={1} value={tempOtp.num5} onChange={(e) => updateNumber(e, "5")} />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Control id="num-6" maxLength={1} value={tempOtp.num6} onChange={(e) => updateNumber(e, "6")} />
                    </Form.Group>
                </div>
                {errors.otp && <span className="info-text error">{errors.otp}</span>}

                <Form.Group className="form-group mb-0">
                    <button className="btn btn-small" onClick={submitForm}>
                        {!loading && "Verify Now"}
                        {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                    </button>

                    <span className="update-number" onClick={() => updatePhone()}>
                        Change Number
                    </span>
                </Form.Group>
            </Form>
        </div>
    );
};

export default VerifyPhone;
