import {
  GET_ORAGANIZATIONS,
  ADD_NEW_ORGANIZATION,
  UPDATE_ORGANIZATION,
  REMOVE_ORGANIZATION,
  SAVE_TERM_PAGES,
} from '../../Constants/Types';
import API from '../../Utils/API';

/**
 * Dashboard Data
 */
export const getDashboardData = regId => dispatch =>
  API.getDashboardData(regId);
export const approveUsers = id => dispatch => API.approveUsers(id);
export const approveCauses = id => dispatch => API.approveCauses(id);

/**
 * Organization List
 */

export const getOrganizationsDispatch = items => ({
  type: GET_ORAGANIZATIONS,
  items,
});
export const saveOrganizationDispatch = item => ({
  type: ADD_NEW_ORGANIZATION,
  item,
});
export const updateOrganizationDispatch = item => ({
  type: UPDATE_ORGANIZATION,
  item,
});
export const removeOrganizationDispatch = item => ({
  type: REMOVE_ORGANIZATION,
  item,
});

export const getOrganizations = data => dispatch =>
  API.getOrganizations(data).then(res => {
    const {items} = res;
    if (res.type === 'success' && items.length > 0) {
      dispatch(getOrganizationsDispatch(items));
    }
    return res;
  });
export const saveOrganization = data => dispatch =>
  API.saveOrganization(data).then(res => {
    const {item} = res;
    if (res.type === 'success' && Object.entries(item).length > 0) {
      item.id = item._id;
      dispatch(saveOrganizationDispatch(item));
    }
    return res;
  });

export const updateOrganization = data => dispatch =>
  API.updateOrganization(data).then(res => {
    const {item} = res;
    if (res.type === 'success' && Object.entries(item).length > 0) {
      dispatch(updateOrganizationDispatch(item));
    }
    return res;
  });

export const removeOrganization = id => dispatch =>
  API.removeOrganization(id).then(res => {
    const {item} = res;
    if (res.type === 'success' && Object.entries(item).length > 0) {
      dispatch(removeOrganizationDispatch(item));
    }
    return res;
  });

export const importOrganizations = data => dispatch =>
  API.importOrganizations(data);

/**
 * Save/Retrive TERM - Privacy Pages
 */
export const dispatchSaveTermPages = pages => ({
  type: SAVE_TERM_PAGES,
  pages,
});
export const saveTermPages = data => dispatch =>
  API.saveTermPages(data).then(res => {
    if (res.type === 'success' && res.pages) {
      dispatch(dispatchSaveTermPages(res.pages));
    }

    return res;
  });
export const getTermPages = id => dispatch =>
  API.getTermPages(id).then(res => {
    if (res.type === 'success' && res.pages) {
      dispatch(dispatchSaveTermPages(res.pages));
    }

    return res;
  });
