import jwtDecode from "jwt-decode";
import store from "../Store";

import { getItem } from "./LocalStorage";

import setAuthHeader from "./setAuthorizationHeader";
import { loginDispatch } from "../Store/Actions/AuthActions";
import API from "../Utils/API";

const authToken = getItem("uplink_auth_token");

if (authToken) {
    const decoded = jwtDecode(authToken);
    const userId = decoded.id;
    const user_type = decoded.user_type;
    setAuthHeader(authToken);
    const user = {
        id: userId,
        user_type,
    };

    user.authToken = authToken;
    store.dispatch(loginDispatch(user));

    API.getUserProfile(userId)
        .then((res) => {
            const { user } = res;

            if (user) {
                user.authToken = authToken;
                store.dispatch(loginDispatch(user));
            }
        })
        .catch((err) => {
            console.log("Err:", err);
        });
}
