import React from 'react';
import {Accordion, Card} from 'react-bootstrap';
import {RenderHTMLBlock} from '../Utils/Helpers';
import {faqs} from '../Constants';

const FaqItems = props => {
  const currentFaqItem = props.current;

  return (
    <Accordion defaultActiveKey={`${currentFaqItem}`} className="accordian">
      {Object.entries(faqs).length > 0 &&
        faqs.map((item, index) => (
          <Accordion.Item eventKey={index} key={`accordian-faq-${index}`}>
            <Accordion.Header>{item.title}</Accordion.Header>
            <Accordion.Body>
              <RenderHTMLBlock container={'div'} content={item.content} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
    </Accordion>
  );
};

export default FaqItems;
