export const getItem = (key) => {
    const value = localStorage.getItem(key);
    return value;
};
export const setItem = (key, value) => {
    try {
        localStorage.setItem(key, value);
        return {
            type: "success",
        };
    } catch (e) {
        return {
            type: "error",
            message: e.message,
        };
    }
};
export const removeItem = (key) => {
    try {
        localStorage.removeItem(key);
        return {
            type: "success",
        };
    } catch (e) {
        return {
            type: "error",
            message: e.message,
        };
    }
};
