import { setItem, removeItem } from "../../Utils/LocalStorage";
import { LOGIN, LOGOUT } from "../../Constants/Types";
import setHeaders from "../../Utils/setAuthorizationHeader";

import API from "../../Utils/API";

// LOGIN ACTION
export const loginDispatch = (user) => ({
    type: LOGIN,
    user,
});

export const requestOtp = (data) => (dispatch) =>
    API.requestOtp(data)
        .then((res) => res)
        .catch((err) => {
            console.log("Err", err);
            return { type: "error", message: err.message };
        });

export const registerOtpRequest = (data) => (dispatch) => API.registerOtpRequest(data);

export const verifyOtp = (data) => (dispatch) =>
    API.verifyOtp(data)
        .then((res) => {
            if (res.type === "success" && res.authToken) {
                const authToken = res.authToken;
                const userData = res.user;

                setItem("uplink_auth_token", authToken);
                setHeaders(authToken);                

                dispatch(loginDispatch(userData));
            }
            return res;
        })
        .catch((err) => {
            console.log("Err", err);
            return { type: "error", message: err.message };
        });

// LOGOUT
export const logoutDispatch = () => ({
    type: LOGOUT,
});

export const logout = () => (dispatch) => {
    removeItem("uplink_auth_token");
    setHeaders();
    dispatch(logoutDispatch());
};
