/* eslint-disable no-useless-computed-key */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Col, Modal, Row, Form, Button, Alert} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';

import {saveUser} from '../../../Store/Actions/UsersAction';
import {validateEmail} from '../../../Utils/Helpers';
import {allowedCountries, categories, usStates} from '../../../Constants';

const EditUser = ({show, toggleModal, user, saveUser}) => {
  const [data, setData] = useState(user);
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [challenges, setChallenges] = useState([]);
  const [validPhone, setValidPhone] = useState({
    value: '',
    required: 0,
  });

  const onChange = e => {
    const tempData = {...data};
    tempData[e.target.name] = e.target.value;

    setData(tempData);
  };

  const setPhone = phone => {
    const tempData = {...data, phone};

    setData(tempData);
  };

  const validateData = () => {
    const errors = {};

    if (!data.name || data.name === '') {
      errors.name = 'Please enter Name.';
    }

    // if (!data.email || data.email === '') {
    //   errors.email = 'Please enter email.';
    // } else
    if (data.email !== '' && !validateEmail(data.email)) {
      errors.email = 'Please enter valid email.';
    }

    if (!data.user_type || data.user_type === '') {
      errors.user_type = 'Please select User role.';
    }

    if (data.phone !== '' && validPhone.value.length !== validPhone.required) {
      errors.phone = 'Please enter valid phone number.';
    }

    return errors;
  };

  const saveUserDetails = () => {
    const errors = validateData();
    setErrors(errors);

    if (Object.entries(errors).length === 0) {
      const tempData = {...data};
      setSaving(true);

      let tempCates = challenges.join(',');
      tempCates = tempCates.replace(/(^,)|(,$)/g, ''); // Removing extra ,(Comma) from Starting and Ending

      tempData.pref_category = tempCates;
      tempData.phone =
        tempData.phone && !tempData.phone.includes('?')
          ? `+${tempData.phone}`
          : tempData.phone;

      saveUser({data: tempData})
        .then(res => {
          console.log(res);

          setSaving(false);
          setAlertData({
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          });
        })
        .catch(err => {
          setSaving(false);
          setAlertData({
            show: true,
            type: 'danger',
            message: err.message,
          });
        });
    }
  };

  const updateChallenge = itemValue => {
    let updated = [...challenges];

    if (!challenges.includes(itemValue)) {
      updated.push(itemValue);
    } else {
      updated = updated.filter(item => item !== itemValue);
    }

    setChallenges(updated);
  };

  const updateUserStatus = e => {
    const status = e.target.checked ? 'Active' : 'Inactive';
    setData({
      ...data,
      status,
    });
  };

  const hideErrMsg = () => {
    setAlertData({
      show: false,
      type: '',
      message: '',
    });
  };

  useEffect(() => {
    let categories = String(data.pref_category);

    if (categories && categories !== '') {
      if (categories.includes(',')) {
        categories = categories.split(',');
      } else {
        categories = [categories];
      }

      setChallenges(categories);
    }
  }, [data.pref_category]);

  return (
    <Modal
      className="medium"
      show={show}
      onHide={() => toggleModal('edit_user_modal', false)}>
      <Modal.Header>
        <Modal.Title>Edit User</Modal.Title>
        <span
          className="close as-link"
          onClick={() => {
            toggleModal('edit_user_modal', false);
          }}>
          x
        </span>
      </Modal.Header>
      <Modal.Body>
        {alertData && alertData.show && (
          <Alert
            variant={alertData.type}
            onClose={() => hideErrMsg()}
            dismissible>
            {alertData.message}
          </Alert>
        )}
        <div className="modal-form-wrapper">
          <Form>
            <Row>
              <Col>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder=""
                    value={data.name}
                    onChange={e => onChange(e)}
                  />
                  {errors.name && (
                    <span className="errorMsg">{errors.name}</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group" controlId="email">
                  <label>Email</label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder=""
                    value={data.email}
                    onChange={e => onChange(e)}
                  />
                  {errors.email && (
                    <span className="errorMsg">{errors.email}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="form-group" controlId="phone">
                  <label>Phone</label>
                  <PhoneInput
                    className="form-control"
                    onlyCountries={allowedCountries}
                    country={'us'}
                    value={data.phone}
                    onChange={(phone, countryData, temp, formatedVal) => {
                      setPhone(phone);
                      setValidPhone({
                        value: formatedVal,
                        required: countryData.format.length,
                      });
                    }}
                  />
                  {errors.phone && (
                    <span className="errorMsg">{errors.phone}</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group" controlId="role">
                  <label>Role</label>
                  <div className="select-dropdown">
                    <select
                      name="user_type"
                      value={data.user_type}
                      onChange={e => onChange(e)}
                      className="form-control">
                      <option value="">Choose Role</option>
                      <option>Administrator</option>
                      <option>Subscriber</option>
                    </select>
                  </div>
                  {errors.user_type && (
                    <span className="errorMsg">{errors.user_type}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="address">
                  <label>Address</label>
                  <Form.Control
                    name="address"
                    type="address"
                    placeholder=""
                    value={data.address}
                    onChange={e => onChange(e)}
                  />
                  {errors.address && (
                    <span className="errorMsg">{errors.address}</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>State</label>

                  <div className="select-dropdown">
                    <select
                      name="state"
                      value={data.state ? data.state : ''}
                      onChange={e => onChange(e)}
                      className="form-control">
                      <option value="">Choose</option>
                      {Object.entries(usStates).length > 0 &&
                        usStates.map((item, index) => (
                          <option value={item} key={`state_index_${index}`}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors.state && (
                    <span className="errorMsg">{errors.state}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="form-group" controlId="role">
                  <label>Preferred Category</label>

                  {categories &&
                    categories.map((item, index) => (
                      <Form.Check
                        key={`category-id-${index}`}
                        type={'checkbox'}
                        id={`category-id-${index}`}
                        label={item}
                        checked={challenges.includes(item)}
                        onChange={() => updateChallenge(item)}
                      />
                    ))}

                  {errors.user_type && (
                    <span className="errorMsg">{errors.user_type}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group  mb-0" controlId="status">
                  <label>Status</label>

                  <Form.Check
                    type="switch"
                    name="status"
                    id="custom-switch"
                    label={data.status}
                    checked={data.status === 'Active' ? true : false}
                    onChange={e => updateUserStatus(e)}
                  />

                  {errors.status && (
                    <span className="errorMsg">{errors.status}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start pt-0">
        <Button
          onClick={() => {
            if (!saving) {
              saveUserDetails();
            }
          }}>
          {!saving && 'Save'}
          {saving && <i className="fas fa-spinner fa-spin" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const mapStateProps = state => ({});

export default connect(mapStateProps, {saveUser})(EditUser);
