import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {LogoDark} from '../Constants/Images';

function Header() {
  return (
    <div className="site-header">
      <div className="container">
        <Row>
          <Col md={3}>
            <div className="logo-wrapper">
              <Link to="/">
                <img src={LogoDark} alt="" />
              </Link>
            </div>
          </Col>
          {/* <Col md={9}>
      <div className="navigation-wrapper"></div>
    </Col> */}
        </Row>
      </div>
    </div>
  );
}

export default Header;
