import React from "react";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Logo } from "../../../Constants/Images";

import { setMenuState } from "../../../Store/Actions/CommanAction";

const Profile = ({ menuState, setMenuState, user }) => {
    return (
        <div className="profile-wrapper">
            <div className="app-logo-wrapper">
                <div className="logo">
                    <Link to="/causes">
                        <Image src={Logo} />
                    </Link>
                </div>
            </div>
            <span className="closeIcon fas fa-times" onClick={() => setMenuState(!menuState)} />
        </div>
    );
};

const mapStateprops = (state) => ({
    menuState: state.menuState,
    user: state.user,
});

export default connect(mapStateprops, { setMenuState })(Profile);
