import React, { useRef } from "react";
import JoditEditor from "jodit-react";

// const config = ["bold", "italic", "underline"];
const config = {
    placeholder: "",
};

const RichEditor = ({ name, value, onChange }) => {
    const editor = useRef(name);

    return <JoditEditor ref={editor} config={config} onChange={onChange} value={value} />;
};

export default RichEditor;
