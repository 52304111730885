import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Alert, Col, Form, Row, Tab, Tabs} from 'react-bootstrap';

import {Breadcrumb} from '../../Components';
import {saveSettings, getSettings} from '../../Store/Actions/CommanAction';
import {importOrganizations} from '../../Store/Actions/AdminAction';
import {updateUrl} from '../../Utils/Helpers';
import {environment} from '../../Utils/env';

const Settings = ({settings, history, getSettings, importOrganizations}) => {
  const [data, setData] = useState({
    admin_email: '',
    app_name: '',
    app_description: '',
    twilio_token: '',
    twilio_sid: '',
    twilio_serviceID: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [activeTab, setActiveTab] = useState('general');
  const [importLoading, setImportLoading] = useState(false);
  const [importDataFile, setImportDataFile] = useState({});

  const setUrlHash = activeTab => {
    console.log('Key:', activeTab);

    setActiveTab(activeTab);

    const newUrl = `/admin/settings/#${activeTab}`;
    updateUrl(newUrl, history);
  };

  const onChange = e => {
    const tempData = {...data};

    if (e.target.type === 'file') {
      tempData[e.target.name] = e.target.files[0];
    } else if (e.target.name === 'enable_youtube') {
      tempData[e.target.name] = e.target.checked ? 'Yes' : '';
    } else {
      tempData[e.target.name] = e.target.value;
    }

    setData(tempData);
  };

  const hideErrMsg = () => {
    setAlertData({
      show: false,
      type: '',
      message: '',
    });
  };

  const fetchSettings = () => {
    const settingKeys = [
      'app_name',
      'admin_email',
      'app_description',
      'twilio_token',
      'twilio_sid',
      'twilio_serviceID',
    ];

    setErrors({});

    getSettings({data: {keys: settingKeys}})
      .then(res => {})
      .catch(err => {
        setAlertData({
          show: true,
          type: 'danger',
          message: err.message,
        });
      });
  };

  const updateSettings = () => {
    if (!loading) {
      setLoading(true);

      saveSettings({data})
        .then(res => {
          setLoading(false);
          setAlertData({
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          });
        })
        .catch(err => {
          setLoading(false);
          setAlertData({
            show: true,
            type: 'danger',
            message: err.message,
          });
        });
    }
  };

  const updateDataFile = e => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      console.log(reader.result);

      const fileData = {
        name: file.name,
        type: file.type,
        base64Data: reader.result,
        size: file.size,
      };

      setImportDataFile(fileData);
    };
  };

  const importData = () => {
    if (!importLoading && Object.entries(importDataFile).length > 0) {
      setImportLoading(true);
      const data = {
        data_file: importDataFile,
      };

      importOrganizations({data})
        .then(res => {
          setImportLoading(false);
          setAlertData({
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          });
        })
        .catch(err => {
          setImportLoading(false);
          setAlertData({
            show: true,
            type: 'danger',
            message: err.message,
          });
        });
    } else {
      setAlertData({
        show: true,
        message: 'Please select CSV file.',
        type: 'warning',
      });
    }
  };

  useEffect(() => {
    setData(settings);
  }, [settings]);

  useEffect(() => {
    fetchSettings();

    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      console.log('Test :', hash);

      setActiveTab(hash);
    }
  }, []);

  return (
    <div className="admin-body">
      <div className="container">
        <Breadcrumb title="Settings" />

        <div className="body-card">
          <div className="heading">
            <h2>Settings</h2>
          </div>

          <div className="body-content">
            {alertData && alertData.show && (
              <div className="alerts p-0">
                <Alert
                  variant={alertData.type}
                  onClose={() => hideErrMsg()}
                  dismissible>
                  {alertData.message}
                </Alert>
              </div>
            )}

            <div className="verticle-tabs mt-3 mb-2">
              <Tabs
                defaultActiveKey={activeTab}
                id="uncontrolled-tab-example"
                onSelect={k => setUrlHash(k)}>
                <Tab
                  eventKey="general"
                  title="App Info"
                  tabattrs={{link: '#general'}}>
                  <div className="tab-heading">
                    <h2>App Info</h2>

                    {/* <span className="btn btn-primary btn-small" onClick={() => {}}>
                          {!loading && "Save"}
                          {loading && <i className="fas fa-spinner fa-spin" />}
                      </span> */}
                  </div>

                  <Row>
                    <Col md={6}>
                      <Form.Group className="form-group" controlId="app_name">
                        <label>App Name</label>
                        <Form.Control
                          name="app_name"
                          type="text"
                          placeholder=""
                          value={data.app_name}
                          onChange={e => onChange(e)}
                        />
                        {errors.app_name && (
                          <span className="errorMsg">{errors.app_name}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="form-group"
                        controlId="admin_email">
                        <label>Admin Email</label>
                        <Form.Control
                          name="admin_email"
                          type="text"
                          placeholder=""
                          value={data.admin_email}
                          onChange={e => onChange(e)}
                        />
                        {errors.admin_email && (
                          <span className="errorMsg">{errors.admin_email}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        className="form-group"
                        controlId="app_description">
                        <label>App Description</label>
                        <Form.Control
                          name="app_description"
                          as="textarea"
                          rows={5}
                          value={data.app_description}
                          onChange={e => onChange(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="tab-footer">
                    <span
                      className="btn btn-primary btn-small"
                      onClick={() => updateSettings()}>
                      {!loading && 'Save'}
                      {loading && <i className="fas fa-spinner fa-spin" />}
                    </span>
                  </div>
                </Tab>

                <Tab eventKey="twilio-sms" title="Twilio SMS">
                  <div className="tab-heading">
                    <h2>Twilio Auth</h2>
                  </div>

                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="form-group"
                        controlId="twilio_token">
                        <label>Token</label>
                        <Form.Control
                          name="twilio_token"
                          type="text"
                          placeholder=""
                          value={data.twilio_token}
                          onChange={e => onChange(e)}
                        />
                        {errors.twilio_token && (
                          <span className="errorMsg">
                            {errors.twilio_token}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="form-group" controlId="twilio_sid">
                        <label>SID</label>
                        <Form.Control
                          name="twilio_sid"
                          type="text"
                          placeholder=""
                          value={data.twilio_sid}
                          onChange={e => onChange(e)}
                        />
                        {errors.twilio_sid && (
                          <span className="errorMsg">{errors.twilio_sid}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="form-group"
                        controlId="twilio_serviceID">
                        <label>Service ID</label>
                        <Form.Control
                          name="twilio_serviceID"
                          type="text"
                          placeholder=""
                          value={data.twilio_serviceID}
                          onChange={e => onChange(e)}
                        />
                        {errors.twilio_serviceID && (
                          <span className="errorMsg">
                            {errors.twilio_serviceID}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="tab-footer">
                    <span
                      className="btn btn-primary btn-small"
                      onClick={() => updateSettings()}>
                      {!loading && 'Save'}
                      {loading && <i className="fas fa-spinner fa-spin" />}
                    </span>
                  </div>
                </Tab>

                {environment === 'Local' && (
                  <Tab
                    eventKey="import-organizations"
                    title="Import Organizations">
                    <div className="tab-heading">
                      <h2>Import Data</h2>
                    </div>

                    <Row>
                      <Col md={6}>
                        <Form.Group
                          className="form-group"
                          controlId="data_file">
                          <label>CSV File</label>
                          <Form.Control
                            name="data_file"
                            type="file"
                            placeholder=""
                            value={data.data_file}
                            onChange={e => updateDataFile(e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="tab-footer">
                      <span
                        className="btn btn-primary btn-small"
                        onClick={() => importData()}>
                        {!importLoading && 'Import'}
                        {importLoading && (
                          <i className="fas fa-spinner fa-spin" />
                        )}
                      </span>
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  settings: state.settings,
});

export default connect(mapStateProps, {
  saveSettings,
  getSettings,
  importOrganizations,
})(Settings);
