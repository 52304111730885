import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert, Col, Form, Row, Tab, Tabs} from 'react-bootstrap';

import {Breadcrumb, RichEditor} from '../../Components';
import {
  saveNotifications,
  getNotifications,
} from '../../Store/Actions/CommanAction';
import {updateUrl} from '../../Utils/Helpers';

const initialData = {
  registration: {
    user: {
      subject: '',
      message: '',
    },
    admin: {
      subject: '',
      message: '',
    },
  },
};

class Notifications extends Component {
  state = {
    data: initialData,
    errors: {},
    is_saving: false,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
    authLoading: false,
    isAuthentication: false,
    activeTab: 'register',
  };

  UNSAFE_componentWillMount = () => {
    const {notifications} = this.props;
    const newState = {};

    if (Object.entries(notifications).length > 0) {
      newState.data = {...this.state.data, ...notifications};
    }

    this.getNotifications();

    if (window.location.hash) {
      const hash = window.location.hash.substring(1);

      newState.activeTab = hash;
    }

    this.setState(newState);
  };

  UNSAFE_componentWillReceiveProps = ({notifications}) => {
    if (Object.entries(notifications).length > 0) {
      const data = {...this.state.data, ...notifications};

      this.setState({
        data,
      });
    }
  };

  setUrlHash = activeTab => {
    console.log('Key:', activeTab);

    this.setState({
      activeTab,
    });

    const newUrl = `/admin/notificatoins/#${activeTab}`;
    updateUrl(newUrl, this.props.history);
  };

  onChange = (event, user, field, value) => {
    const data = {...this.state.data};

    data[event][user][field] = value;

    this.setState({
      data,
    });
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  getNotifications = () => {
    const keys = ['registration'];

    this.props
      .getNotifications({data: {keys}})
      .then(res => {})
      .catch(err => {});
  };

  saveNotifications = () => {
    const {data, is_saving} = this.state;

    if (!is_saving) {
      this.setState({is_saving: true});

      const tempData = {...data};

      this.props
        .saveNotifications({data: tempData})
        .then(res => {
          this.setState({
            is_saving: false,
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        })
        .catch(err => {
          this.setState({
            is_saving: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  renderTabs = event => {
    const {data, errors} = this.state;

    const adminSubject =
      data && data[event] && data[event]['admin']['subject']
        ? data[event]['admin']['subject']
        : '';
    const adminMessage =
      data && data[event] && data[event]['admin']['message']
        ? data[event]['admin']['message']
        : '';

    const userSubject =
      data && data[event] && data[event]['user']['subject']
        ? data[event]['user']['subject']
        : '';
    const userMessage =
      data && data[event] && data[event]['user']['message']
        ? data[event]['user']['message']
        : '';

    return (
      <div className="regular-tabs">
        <Tabs>
          <Tab eventKey={`${event}-admin`} title="Admin">
            <Row>
              <Col md={12}>
                <Form.Group
                  className="form-group"
                  controlId={`${event}_admin_mail_subject`}>
                  <label>Subject</label>
                  <Form.Control
                    name={`${event}_admin_mail_subject`}
                    type="text"
                    placeholder=""
                    value={adminSubject}
                    onChange={e =>
                      this.onChange(event, 'admin', 'subject', e.target.value)
                    }
                  />
                  {errors.twilio_token && (
                    <span className="errorMsg">{errors.twilio_token}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group
                  className="form-group mb-0"
                  controlId={`${event}_admin_mail_message`}>
                  <label>Message</label>

                  <RichEditor
                    name={`${event}_admin_mail_message`}
                    value={adminMessage}
                    onChange={value =>
                      this.onChange(event, 'admin', 'message', value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey={`${event}-user`} title="User">
            <Row>
              <Col md={12}>
                <Form.Group
                  className="form-group"
                  controlId={`${event}_user_mail_subject`}>
                  <label>Subject</label>
                  <Form.Control
                    name={`${event}_user_mail_subject`}
                    type="text"
                    placeholder=""
                    value={userSubject}
                    onChange={e =>
                      this.onChange(event, 'user', 'subject', e.target.value)
                    }
                  />
                  {errors.twilio_token && (
                    <span className="errorMsg">{errors.twilio_token}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group
                  className="form-group mb-0"
                  controlId={`${event}_user_mail_message`}>
                  <label>Message</label>

                  <RichEditor
                    name={`${event}_user_mail_message`}
                    value={userMessage}
                    onChange={value =>
                      this.onChange(event, 'user', 'message', value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    );
  };

  render() {
    const {is_saving, alertData, activeTab} = this.state;

    return (
      <div className="admin-body">
        <div className="container">
          <Breadcrumb title="Notifications" />

          <div className="body-card">
            <div className="heading">
              <h2>Notifications</h2>
            </div>

            <div className="body-content">
              {alertData && alertData.show && (
                <div className="alerts p-0">
                  <Alert
                    variant={alertData.type}
                    onClose={() => this.hideErrMsg()}
                    dismissible>
                    {alertData.message}
                  </Alert>
                </div>
              )}

              <div className="verticle-tabs mt-3 mb-2">
                <Tabs
                  defaultActiveKey={activeTab}
                  id="uncontrolled-tab-example"
                  onSelect={k => this.setUrlHash(k)}>
                  <Tab eventKey="register" title="Registration">
                    <div className="tab-heading">
                      <h2>Registration</h2>
                    </div>

                    {this.renderTabs('registration')}

                    <div className="mb-3">
                      <span className="info-text">[user_name] - User Name</span>
                      <span className="info-text">
                        [user_phone] - Phone Number
                      </span>
                      <span className="info-text">[user_email] - Email</span>
                    </div>

                    <div className="tab-footer">
                      <span
                        className="btn btn-primary btn-small"
                        onClick={() => this.saveNotifications()}>
                        {!is_saving && 'Save'}
                        {is_saving && <i className="fas fa-spinner fa-spin" />}
                      </span>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateProps = state => ({
  notifications: state.notifications,
});

export default connect(mapStateProps, {saveNotifications, getNotifications})(
  Notifications,
);
