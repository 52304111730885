import {
  GET_USERS,
  ADD_NEW_USER,
  UPDATE_USER,
  REMOVE_USER,
} from '../../Constants/Types';
import API from '../../Utils/API';

/**
 * Users Actions
 **/

// Get Users
export const getUsersDispatch = users => ({
  type: GET_USERS,
  users,
});
export const getAllUsers = page => dispatch =>
  API.getAllUsers(page).then(res => {
    return res;
  });
export const getUsers = page => dispatch =>
  API.getUsers(page).then(res => {
    if (res.type !== 'error' && res.items) {
      const {items} = res;
      dispatch(getUsersDispatch(items));
    }
    return res;
  });

// Update Users
export const saveUserDispatch = user => ({
  type: ADD_NEW_USER,
  user,
});
export const updateUserDispatch = user => ({
  type: UPDATE_USER,
  user,
});
export const addUser = data => dispatch =>
  API.addUser(data).then(res => {
    if (res.type !== 'error') {
      const {item} = res;

      dispatch(saveUserDispatch(item));
    }
    return res;
  });

export const saveUser = data => dispatch =>
  API.updateUser(data).then(res => {
    if (res.type !== 'error') {
      const {item} = res;

      dispatch(updateUserDispatch(item));
    }
    return res;
  });

// Remove Users
export const removeUserDispatch = user => ({
  type: REMOVE_USER,
  user,
});
export const removeUser = data => dispatch =>
  API.removeUser(data).then(res => {
    if (res.type !== 'error') {
      const {item} = res;
      dispatch(removeUserDispatch(item));
    }
    return res;
  });
