export const allowedCountries = ['in', 'us'];
export const usStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const categories = [
  'Mental Health',
  'Substance Abuse',
  'Support Groups',
];

export const faqs = [
  {
    title: 'Is Uplift U only for people that live with mental illnesses?',
    content:
      'Uplift U is a great way for anyone to become more self aware of their feelings and actions over time. Our focus is mental health which doesn’t have to involve a diagnosis.',
  },
  {
    title: 'Is Uplift U free?',
    content:
      'Yes. Uplift you is a resource for you to explore your thoughts and resources free of charge.',
  },
  {
    title: 'Can I add a journal entry without scheduled prompts?',
    content:
      'Absolutely! Uplift U has a scheduling option to assist you in forming an ongoing habit of self exploration. However, we encourage you to journal as you see fit.',
  },
  {
    title: 'Can others see my journal?',
    content:
      'Your thoughts and actions are safe and protected. Uplift U will never read or share your journal without your consent through future features.',
  }, 
  {
    title: 'If I feel suicidal, should I use this app?',
    content:
      'If you feel suicidal at this moment, please dial 988. We value you and want you to share your journey but trained professionals are the best path if you are having suicidal thoughts.Is Uplift U only for people that live with mental illnesses? Uplift U is a great way for anyone to become more self aware of their feelings and actions over time. Our focus is mental health which doesn’t have to involve a diagnosis.',
  },
];
